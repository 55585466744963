import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { IconSize, IconType } from '@isp/icon';
import Modal from '@isp/modal';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import ModalSuccess from '@Shared/modals/SuccessModal';
import * as ModalActions from '@Reducers/modals/actions';

const RequestCreatedModal = () => {
	const { t } = useTranslation(['common', 'account']);
	const dispatch = useDispatch();
	const modalActions = bindActionCreators(ModalActions, dispatch);

	const onClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		modalActions.TOGGLE_REQUEST_CREATED_MODAL();
	};

	return (
		<Modal
			className="color-black"
			primaryButtonText={t('common:close')}
			primaryButtonAction={onClose}
			onClose={onClose}
			footerAction
			header
		>
			<ModalSuccess
				iconSize={IconSize.VERY_HUGE}
				title={t('account:company-info-change-modal-success-title')}
				description={t('account:company-info-change-modal-success-description')}
				icon={IconType.ACTIVATE}
			/>
		</Modal>
	);
};

export default RequestCreatedModal;
