import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Input, { InputSubThemes, InputThemes } from '@isp/input';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Patterns } from '@Constants/common';
import { AddVehicleVINErrorMessages } from '@Constants/vehicle';

interface VINInputProps {
	isLicensePlateAllowed: boolean;
	VINEditDisabled: boolean;
	loadingSaveVehicles: boolean;
	VIN: string;
	loadingCheckVehicle: boolean;
	showErrorMessage: boolean;
	inputRef: RefObject<HTMLInputElement>;
	model: string;
	getMessage: () => AddVehicleVINErrorMessages;
	setVIN: Dispatch<SetStateAction<string>>;
	onInvalidVIN: () => void;
	onValidVIN: (vin: any) => Promise<void>;
}

export default function VINInput(props: VINInputProps) {
	const { t: translate } = useTranslation(['vehicles']);
	const {
		isLicensePlateAllowed,
		loadingSaveVehicles,
		VINEditDisabled,
		VIN,
		loadingCheckVehicle,
		showErrorMessage,
		inputRef,
		setVIN,
		onValidVIN,
		onInvalidVIN,
		getMessage,
		model,
	} = props;

	return (
		<div className={classNames({ 'w-38-p': !isLicensePlateAllowed })}>
			<Input
				required
				disabled={loadingSaveVehicles || VINEditDisabled}
				onChangeTrimmed
				theme={InputThemes.FLAT}
				subTheme={InputSubThemes.GRAY}
				value={VIN}
				label={translate('vehicles:vin')}
				placeholder={`${translate('vehicles:vin')}*`}
				onChange={val => setVIN(val.toUpperCase())}
				onInvalid={() => onInvalidVIN()}
				onValid={value => onValidVIN(value)}
				pattern={Patterns.VIN_LENGTH_PATTERN}
				loading={loadingCheckVehicle}
				valueError={showErrorMessage}
				inputRef={inputRef}
				onEnter={() => onValidVIN(VIN)}
				dataQA={DATA_QA.ADD_VEHICLE_MODAL_VIN_INPUT}
			/>
			{!loadingCheckVehicle && (
				<div className="m-t-2">
					<p className="fs-12 DaimlerCS-Regular color-blood-red" data-qa={DATA_QA.ADD_VEHICLE_MODAL_ERROR_TEXT}>
						{showErrorMessage && translate(getMessage())}
					</p>
					<p className="p-l-4">{!showErrorMessage && model}</p>
				</div>
			)}
		</div>
	);
}
