import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@Redux';
import Modal from '@isp/modal';
import Loading, { LoadingSizes } from '@isp/loading';
import { PartsOrderDetails, getPartsOrderDetails } from '@Apis/orders';
import { TOGGLE_PARTS_ORDER_DETAIL_MODAL } from '@Reducers/modals/actions';
import ISPError from '@Shared/error';
import OrderList from '@Shared/modals/PartsOrderDetailsModal/OrderList';
import OrderInfo from '@Shared/modals/PartsOrderDetailsModal/OrderInfo';
import OrderPriceSummary from '@Shared/modals/PartsOrderDetailsModal/OrderPriceSummary';
import OrderExport from '@Shared/modals/PartsOrderDetailsModal/OrderExport';
import { PrintableDocument } from '@Constants/print';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';
import './index.scss';

const ISPPrint = dynamic(() => import('@Shared/Print'), { ssr: false });

const PartsOrderDetailsModal = () => {
	const dispatch = useDispatch();
	const orderId = useSelector<RootState, string>(state => state.modals.partsOrderId);
	const [orderDetails, setOrderDetails] = useState<PartsOrderDetails>();
	const [orderDetailsError, setOrderDetailsError] = useState<boolean>(false);

	const updateModalContent = () => {
		setOrderDetailsError(false);
		getPartsOrderDetails(orderId)
			.then(data => setOrderDetails(data))
			.catch(() => setOrderDetailsError(true));
	};

	useEffect(() => {
		if (orderId) updateModalContent();
	}, [orderId]);

	return (
		<Modal
			show
			header
			contentClassName={orderDetails ? 'parts-order-detail-modal' : 'train train__center train__justify-center'}
			onClose={() => dispatch(TOGGLE_PARTS_ORDER_DETAIL_MODAL())}
		>
			{!orderDetails && !orderDetailsError && <LoadingData />}
			{orderDetails && <Content orderDetails={orderDetails} orderId={orderId} />}
			{orderDetailsError && <ISPError className="no-white-space" />}
		</Modal>
	);
};

const LoadingData = () => {
	const { t } = useTranslation(['common']);
	return (
		<div className="disp-flex-column train__center">
			<Loading size={LoadingSizes.SMALLER} />
			<div className="no-white-space fs-20">{t('common:loading-data')}</div>
		</div>
	);
};

const Content = ({ orderDetails, orderId }) => {
	const { t } = useTranslation(['orders', 'common']);
	return (
		<div className="parts-order-detail-modal__content">
			<div className="train train__space-between train__center m-b-8">
				<div className="DaimlerCAC-Regular fs-38">{`${t('orders:order')} ${orderDetails.orderNumber}`}</div>
				<div className="train">
					<OrderExport orderId={orderId} />
					<ISPPrint
						dataQA={DATA_QA.PARTS_ORDER_DETAIL_MODAL_PRINT_BUTTON}
						printLabel={t('common:print')}
						data={orderDetails}
						documentType={PrintableDocument.ORDER}
						gtmId={getComponentId([Pages.ORDERS, Components.ORDER_DETAILS, Buttons.PRINT])}
					/>
				</div>
			</div>
			<div className="m-b-10">
				<OrderInfo orderDetails={orderDetails} />
			</div>
			<OrderList orderDetails={orderDetails} />
			<div className="train train__flex-end m-t-2">
				<OrderPriceSummary orderPriceInfo={orderDetails.price} />
			</div>
		</div>
	);
};

export default PartsOrderDetailsModal;
