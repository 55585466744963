import { useEffect } from 'react';

export const useAnimation = boxWrapper => {
	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			if (boxWrapper.current && !boxWrapper?.current?.classList?.contains('animation')) {
				boxWrapper?.current?.classList.toggle('animation', entries[0].isIntersecting);
			}
		});

		observer.observe(boxWrapper?.current);
	}, []);
};
