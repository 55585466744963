import client from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import get from 'lodash/get';
import find from 'lodash/find';

export const getMetaSettings = async (pageType, market, language) => {
	const response = await callMetaData(market, language);
	const pages = get(response, 'page');
	const filteredMetaData = find(pages, item => item.pageName === pageType);
	return filteredMetaData ? filteredMetaData.metaSettings : null;
};

export const callMetaData = (market, language) => {
	const id = CONFIG.META_DATA;

	return client(`/content/${id}`, market, language)
		.then()
		.catch(() => {});
};
