import get from 'lodash/get';
import { withRouter } from 'next/router';
import Layout from '@Layouts/main';
import { SeoMeta } from '@Umbraco/metaData';
import { SidebarTabs } from '@Constants/menu';
import { FeedbackBackground } from '@Constants/home';
import { LayoutBackgrounds } from '@Constants/common';
import { PersistentFC } from './ComponentTypes';

export interface withLayoutOptions {
	activeTab?: SidebarTabs;
	background?: LayoutBackgrounds;
	canonicalURL?: string;
	completeOverlay?: boolean;
	campaigns?: boolean;
	metaSettings?: SeoMeta;
	news?: boolean;
	showVinDropdown?: boolean;
	showBackToVehicles?: boolean;
	showBackToCatalogueEntry?: boolean;
	showSidebar?: boolean;
	showRetailerDropdown?: boolean;
	showNetPriceToggle?: boolean;
	showFeedback?: boolean | FeedbackBackground;
	hideNotifications?: boolean;
	hideToolbar?: boolean;
}

export const withLayout = (WrappedComponent, options?: withLayoutOptions) => {
	WrappedComponent.getLayout = (page, props) => {
		const { canonicalURL, metaSettings } = props;
		const metaData: SeoMeta = metaSettings?.seoMeta;
		const activeTab = get(options, 'activeTab');
		const background = get(options, 'background');
		const completeOverlay = get(options, 'completeOverlay');
		const campaigns = get(options, 'campaigns');
		const news = get(options, 'news');
		const showVinDropdown = get(options, 'showVinDropdown');
		const showBackToVehicles = get(options, 'showBackToVehicles');
		const showBackToCatalogueEntry = get(options, 'showBackToCatalogueEntry');
		const showSidebar = get(options, 'showSidebar');
		const showRetailerDropdown = get(options, 'showRetailerDropdown');
		const showNetPriceToggle = get(options, 'showNetPriceToggle');
		const showFeedback = get(options, 'showFeedback');
		const hideNotifications = get(options, 'hideNotifications');
		const hideToolbar = get(options, 'hideToolbar');
		return (
			<Layout
				{...(canonicalURL && { canonicalURL })}
				{...(background && { background })}
				{...(metaData && { metaData })}
				{...(activeTab && { activeTab })}
				{...(completeOverlay && { completeOverlay })}
				{...(campaigns && { campaigns })}
				{...(news && { news })}
				{...(showVinDropdown && { showVinDropdown })}
				{...{ showBackToVehicles }}
				{...{ showBackToCatalogueEntry }}
				{...(showSidebar && { showSidebar })}
				{...(showRetailerDropdown && { showRetailerDropdown })}
				{...(showNetPriceToggle && { showNetPriceToggle })}
				{...(showFeedback && { showFeedback })}
				{...(hideNotifications && { hideNotifications })}
				{...(hideToolbar && { hideToolbar })}
			>
				{page}
			</Layout>
		);
	};
	return WrappedComponent;
};

export const withRouterLayout = (WrappedComponent, options?: withLayoutOptions) => {
	const RoutedComponent = withRouter(WrappedComponent);
	const LayoutComponent = withLayout(WrappedComponent, options);
	(RoutedComponent as PersistentFC).getLayout = LayoutComponent.getLayout;
	(RoutedComponent as PersistentFC).getInitialProps = WrappedComponent.getInitialProps;
	return RoutedComponent;
};
