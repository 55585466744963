import { VFC, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { getRoutesAs } from '@Routes';
import router from 'next/router';
import { RootState } from '@Redux';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import Separator, { BorderVariant } from '@isp/separator';
import { BannerNotificationData, PrivacyType, getBannerNotifications } from '@Umbraco/notifications';
import { TOGGLE_BANNER_NOTIFICATION_MODAL } from '@Reducers/modals/actions';
import NextI18Next from '@Lib/i18n';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { filterCMSNotificationsByDate } from '@Helpers/notifications';
import { Events } from '@Constants/common';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import './index.scss';

interface BannerNotificationProps {
	market: string;
}

const BannerNotification: VFC<BannerNotificationProps> = ({ market }) => {
	const [bannerNotifications, setBannerNotifications] = useState<BannerNotificationData[]>([]);
	const dispatch = useDispatch();
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);

	const sortBannerNotificationByPrivacy = (bannerNotifications: BannerNotificationData[]) => {
		const NOTIFICATION_PRIVACY_SCORE_MAPPING = {
			[PrivacyType.PRIVATE]: 0,
			[PrivacyType.PUBLIC]: 1,
			[PrivacyType.NO_PRIVACY]: 2,
		};

		bannerNotifications.sort(
			(firstNotification: BannerNotificationData, secondNotification: BannerNotificationData) => {
				const difference =
					NOTIFICATION_PRIVACY_SCORE_MAPPING[secondNotification.privacy] -
					NOTIFICATION_PRIVACY_SCORE_MAPPING[firstNotification.privacy];

				return !difference ? (secondNotification.sortOrder || 0) - (firstNotification.sortOrder || 0) : difference;
			}
		);
	};

	const splitAndFilterBannerNotificationsByType = (notifications: BannerNotificationData[]) => {
		const filteredNotifications = filterCMSNotificationsByDate(notifications) as BannerNotificationData[];
		sortBannerNotificationByPrivacy(filteredNotifications);
		setBannerNotifications(filteredNotifications);
	};

	const getNotifications = () => {
		const language = geti18nLanguage(NextI18Next.i18n.language);
		getBannerNotifications(market.toLowerCase(), language)
			.then((data: BannerNotificationData[]) => splitAndFilterBannerNotificationsByType(data))
			.catch(() => setBannerNotifications([]));
	};

	const handleReadMoreClick = (e, bannerNotification: BannerNotificationData) => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		if (bannerNotification.readMoreLink !== '')
			router.push(bannerNotification.readMoreLink, getRoutesAs(bannerNotification.readMoreLink, { market }));
		else dispatch(TOGGLE_BANNER_NOTIFICATION_MODAL(bannerNotification));
	};

	const handleLanguageChangeEvents = () => {
		getNotifications();
	};

	useEffect(() => {
		NextI18Next.i18n.on(Events.LANGUAGE_CHANGED, handleLanguageChangeEvents);
		getNotifications();
		return () => {
			NextI18Next.i18n.off(Events.LANGUAGE_CHANGED, handleLanguageChangeEvents);
		};
	}, []);

	return (
		<div
			className={`header__banner-notifications train__column bg-black-three ${
				isEmpty(bannerNotifications) ? 'disp-none' : 'disp-flex'
			}`}
		>
			{bannerNotifications.map((bannerNotification: BannerNotificationData, index) => (
				<NotificationItem
					bannerNotification={bannerNotification}
					isUserLoggedIn={isUserLoggedIn}
					handleReadMoreClick={handleReadMoreClick}
					key={`banner-notification-${isUserLoggedIn ? 'private' : 'public'}-${index}`}
				/>
			))}
		</div>
	);
};

const NotificationItem = ({ bannerNotification, isUserLoggedIn, handleReadMoreClick }) => {
	const { t: translate } = useTranslation(['common']);

	const isReadMore = bannerNotification.readMoreLink !== '' || bannerNotification.modalContent !== '';
	const showNotification =
		(bannerNotification.privacy === PrivacyType.PUBLIC && !isUserLoggedIn) ||
		(bannerNotification.privacy === PrivacyType.PRIVATE && isUserLoggedIn) ||
		bannerNotification.privacy === PrivacyType.NO_PRIVACY;
	return (
		showNotification && (
			<>
				<div className="disp-flex train__center m-v-1 p-h-3">
					<ISPIcon className="p-r-1" type={IconType.ERROR_CIRCLE} variant={IconVariant.AMBER} size={IconSize.SMALL} />
					<p className="DaimlerCS-Regular fs-14 color-white p-l-1">
						{`${bannerNotification.notificationMessage} `}
						{isReadMore && (
							<span
								data-hover-azure
								className="DaimlerCS-Regular fs-14 color-white text-underline cursor-pointer"
								id={getComponentId(
									[
										Pages.HOME,
										Components.NOTIFICATION,
										Buttons.READ_MORE,
										'banner-notifications',
										`${isUserLoggedIn ? 'logged-in-page' : 'public-page'}`,
									],
									true,
									true
								)}
								onClick={e => handleReadMoreClick(e, bannerNotification)}
							>
								{translate('common:read-more')}
							</span>
						)}
					</p>
				</div>
				<Separator color={BorderVariant.GREYISHBROWN2} />
			</>
		)
	);
};

const mapStateToProps = state => ({
	isUserLoggedIn: state.user.isUserLoggedIn,
});

export default connect(mapStateToProps)(BannerNotification);
