import { FunctionComponent, ReactElement } from 'react';
import Grid from '@isp/grid';
import GridItem from '@isp/grid-item';
import Icon, { IconType, IconSize, IconVariant } from '@isp/icon';
import './index.scss';
import classNames from 'classnames';

export interface ModalSuccessProps {
	title: string;
	description: ReactElement | string;
	secondaryDescription?: string;
	icon: IconType;
	iconSize?: IconSize;
	isOrderPpv?: boolean;
}

const ModalSuccess: FunctionComponent<ModalSuccessProps> = ({
	title,
	description,
	secondaryDescription,
	icon,
	iconSize,
	isOrderPpv,
}) => {
	return (
		<Grid guterless className="h-100-p w-100-p">
			<GridItem size="2/12">
				<Grid className="h-100-p" vAlign="center">
					<GridItem>
						<Icon
							className="float-left"
							type={icon || IconType.ACTIVATE}
							size={iconSize || IconSize.VERY_HUGE}
							variant={IconVariant.BLUE}
						/>
					</GridItem>
				</Grid>
			</GridItem>
			{!isOrderPpv && <GridItem size="1/12" />}
			<GridItem size={isOrderPpv ? '9/12' : '8/12'}>
				<h3 className={classNames('DaimlerCAC-Regular fw-400 p-v-5', { 'fs-30': !isOrderPpv, 'fs-38': isOrderPpv })}>
					{title}
				</h3>
				<p className="p-b-10 fs-16 modal-success__description">
					{description}
					{secondaryDescription && <div className="p-t-1">{secondaryDescription}</div>}
				</p>
			</GridItem>
		</Grid>
	);
};

export default ModalSuccess;
