import { client } from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import get from 'lodash/get';
import { NotificationsTypes } from '@Constants/notifications';

export interface DateRange {
	startDate?: string;
	endDate?: string;
}

export enum IncidentType {
	INCIDENT = 'INCIDENT',
	PLANNED_MAINTENANCE = 'MAINTENANCE',
	GENERAL_INFORMATION = 'INFORMATION',
}

export interface IncidentManagamentNotificationItem extends DateRange {
	type: NotificationsTypes;
	affectedProducts: string[];
	incidentType: IncidentType;
	title: string;
	description: string;
	icon: string;
	allMarkets: boolean;
	updateDate?: string;
	markets: string[];
	message: string;
	maintenanceStart: string;
	maintenanceEnd: string;
}

export enum PrivacyType {
	PUBLIC = 'public',
	PRIVATE = 'private',
	NO_PRIVACY = 'noPrivacy',
}

export interface BannerNotificationData extends DateRange {
	id: string;
	privacy: PrivacyType;
	notificationMessage: string;
	readMoreLink: string;
	modalContent: string;
	sortOrder: number;
}

const toCustomNotificationItem = item => ({
	title: item.title,
	description: item.description,
	button: get(item, 'action[0]', null),
	date: item.date,
});

const toIncidentManagementNotificationItem = item => {
	return {
		title: item.title,
		description: item.description,
		icon: item.icon,
		type: item.type,
		affectedProducts: get(item, 'affectedProducts', []),
		startDate: get(item, 'startDate', null),
		endDate: get(item, 'endDate', null),
		updateDate: get(item, '_updateDate', null),
		markets: get(item, 'markets', []),
		allMarkets: get(item, 'allMarkets', false),
		message: get(item, 'message.value', ''),
		incidentType: get(item, 'type', IncidentType.GENERAL_INFORMATION),
		maintenanceStart: get(item, 'maintenanceStart'),
		maintenanceEnd: get(item, 'maintenanceEnd'),
	};
};

const toBannerNotificationItem = item => ({
	id: item?._id,
	privacy: item?.privacy,
	startDate: item?.startDate || null,
	endDate: item?.endDate || null,
	notificationMessage: item?.notificationMessage,
	readMoreLink: item?.readMoreLink,
	modalContent: item?.modalContent,
	sortOrder: item?.sortOrder,
});

export function getCustomNotifications(market: string, lang: string) {
	const id = CONFIG.CUSTOM_NOTIFICATIONS;
	return client(`/content/${id}/children`, market, lang)
		.then(response => {
			const customNotifications = get(response, '_embedded.content', []);
			return customNotifications.map(toCustomNotificationItem);
		})
		.catch(err => {
			throw err;
		});
}

export function getIncidentManagementNotifications(market: string, lang: string) {
	const id = CONFIG.INCIDENT_MANAGEMENT_NOTIFICATIONS;
	return client(`/content/${id}/children`, market, lang)
		.then(response => {
			const notifications = get(response, '_embedded.content', []);
			return notifications.map(toIncidentManagementNotificationItem);
		})
		.catch(err => {
			throw err;
		});
}

export function getBannerNotifications(market: string, lang: string) {
	const id = CONFIG.BANNER_NOTIFICATIONS;
	return client(`/content/${id}/children`, market, lang)
		.then(response => {
			const notifications = get(response, '_embedded.content', []);
			return notifications.map(toBannerNotificationItem);
		})
		.catch(err => {
			throw err;
		});
}
