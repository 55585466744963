import { useSelector } from 'react-redux';
import truncate from 'lodash/truncate';
import ISPLink, { LinkVariant } from '@isp/link';
import { RootState } from '@Redux';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import ISPTextButton, { HoverTypes, TextColors } from '@isp/text-button';
import Button, { ButtonVariant } from '@isp/button';
import { register } from '@User-operations';
import {
	Buttons,
	Components,
	EventActions,
	EventCategories,
	getComponentId,
	Pages,
	TagManagerClassNames,
} from '@Constants/google-analytics';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Events, WindowTargetAttributes } from '@Constants/common';
import { MAX_LENGTH_OF_USERNAME } from '@Constants/account';
import './index.scss';

const Authenticate = ({ t, isUserLoggedIn, authURL, returnURL, displayLogout }) => {
	const userFirstName = useSelector<RootState>(state => state.user.firstName);
	const userLastName = useSelector<RootState>(state => state.user.lastName);
	const truncatedName = truncate(`(${userFirstName} ${userLastName})`, {
		length: MAX_LENGTH_OF_USERNAME,
		omission: '...)',
	});

	const handleLoginClick = e => {
		PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.LINK,
			action: EventActions.LOGIN,
		});
	};

	const onRegisterClick = e => {
		PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.FEATURE,
			action: EventActions.REGISTRATION,
			label: EventActions.START,
			custom: undefined,
		});
		register();
	};

	const onLogoutClick = e => {
		displayLogout();
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
	};
	return (
		<>
			{!isUserLoggedIn && (
				<div className="disp-flex">
					<ISPLink
						variant={LinkVariant.SECONDARY}
						destination={`${authURL}?returnURL=${returnURL}`}
						target={WindowTargetAttributes.SELF}
						className={`${TagManagerClassNames.LOGIN} fs-12 p-r-4 color-white DaimlerCS-Bold header__login`}
						onClick={handleLoginClick}
						dataQA={DATA_QA.HEADER_LOGIN_BUTTON}
						relNoFollow
						id={getComponentId([Pages.GLOBAL, Components.LOGIN, Buttons.LINK])}
					>
						<Button variant={ButtonVariant.TERTIARY} outline className="p-h-4 fs-16">
							{t('login')}
						</Button>
					</ISPLink>
					<Button
						id={getComponentId([Pages.GLOBAL, Components.REGISTER, Buttons.LINK])}
						variant={ButtonVariant.PRIMARY}
						onClick={onRegisterClick}
						className="p-h-4 fs-16 m-r-4 header__register"
					>
						{t('common:get-started')}
					</Button>
				</div>
			)}
			{isUserLoggedIn && (
				<ISPTextButton
					onClick={onLogoutClick}
					color={TextColors.VERY_LIGHT_PINK}
					hoverType={HoverTypes.AZURE}
					dataQA={DATA_QA.HEADER_LOGOUT_BUTTON}
					className="header__logout disp-flex w-max p-r-6"
					id={getComponentId([Pages.GLOBAL, Components.LOGOUT, Buttons.LINK])}
				>
					<span>{`${t('logout')} ${truncatedName}`}</span>
				</ISPTextButton>
			)}
		</>
	);
};

export default Authenticate;
