import { useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Button, { ButtonVariant } from '@isp/button';
import Icon, { IconType, IconVariant } from '@isp/icon';
import TextArea from '@isp/text-area';
import { Trans, useTranslation } from '@Lib/i18n';
import ISPLink, { LinkVariant } from '@isp/link';
import routes, { getRoutesAs } from '@Routes';
import { triggerScrollEvent } from '@Helpers/common/document';
import ISPError from '@Shared/error';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import './index.scss';

const AdditionalFeedback = ({ onSubmit }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [notes, setNotes] = useState(null);
	const [showInput, setShowInput] = useState(true);
	const [noteSubmitted, setNoteSubmitted] = useState(false);

	const { t } = useTranslation(['common', 'home']);
	const router = useRouter();
	const { market } = router.query;

	const onClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		setShowInput(false);
		triggerScrollEvent();
	};

	const onNoteSumit = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		setError(false);
		setLoading(true);
		onSubmit(notes)
			.then(() => {
				setNoteSubmitted(true);
				setShowInput(false);
			})
			.catch(() => setError(true))
			.finally(() => {
				setLoading(false);
				triggerScrollEvent();
			});
	};

	return (
		<div className="p-v-7">
			<div className="disp-flex p-3 train__center train__justify-center grid--item-gap-3 DaimlerCS-Bold fs-16">
				<Icon type={IconType.CHECKMARK} variant={IconVariant.AZURE} />
				{!noteSubmitted && <div data-qa={DATA_QA.FEEDBACK_INITIAL_THANKS}>{t('home:feedback-thanks')}</div>}
				{noteSubmitted && (
					<div>
						<div>{t('home:feedback-submit-thanks')}</div>
						<div data-qa={DATA_QA.FEEDBACK_ADDITIONAL_THANKS} className="DaimlerCS-Regular">
							{t('home:feedback-submit-thanks-description')}
						</div>
					</div>
				)}
			</div>
			<div
				className={classNames('disp-grid grid--item-gap-3 feedback__additional-note', {
					'feedback__additional-note--closed': !showInput,
				})}
			>
				<div data-qa={DATA_QA.FEEDBACK_ADDITIONAL_TEXTAREA}>
					<TextArea onChange={setNotes} value={notes} placeholder={t('home:feedback-notes-placeholder')} />
				</div>
				<div className="text-justify color-black-two fs-16">
					<Trans i18nKey="home:feedback-legal-text">
						{'Legal text translation'}
						<ISPLink
							className="p-l-1"
							inline
							underline
							variant={LinkVariant.SECONDARY}
							destination={getRoutesAs(routes.LEGAL, { market })}
							disableHref
							onClick={() => router.push(routes.LEGAL, getRoutesAs(routes.LEGAL, { market }))}
						>
							{'Data protection link'}
						</ISPLink>
					</Trans>
				</div>
				{error && <ISPError direction="flex-end" />}
				<div className="disp-flex train__space-between">
					<Button
						className="p-h-8"
						id={getComponentId([Pages.GLOBAL, Components.FEEDBACK, Buttons.CLOSE])}
						dataQA={DATA_QA.FEEDBACK_CLOSE}
						disabled={loading}
						variant={ButtonVariant.FLAT}
						onClick={onClose}
					>
						{t('common:close')}
					</Button>
					<Button
						className="p-h-8"
						id={getComponentId([Pages.GLOBAL, Components.FEEDBACK, Buttons.SEND])}
						dataQA={DATA_QA.FEEDBACK_SEND}
						disabled={loading || !notes}
						onClick={onNoteSumit}
					>
						{t('common:send')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AdditionalFeedback;
