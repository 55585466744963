import { actionTypes } from '@Reducers/remanSearch/actionTypes';
import { ModalScreenTypes } from '@Constants/parts';

export const SET_COUNTRY_INFO = countryInfo => dispatch => {
	dispatch({
		type: actionTypes.SET_COUNTRY_INFO,
		countryInfo,
	});
};

export const SET_SEARCHED_PRODUCT_WITH_PART_NUMBER = (data = []) => dispatch => {
	dispatch({
		type: actionTypes.SET_SEARCHED_PRODUCT_WITH_PART_NUMBER,
		productsWithPartNumberSearched: data,
	});
};

export const SET_SEARCHED_PRODUCT_WITH_VIN_NUMBER = (data = []) => dispatch => {
	dispatch({
		type: actionTypes.SET_SEARCHED_PRODUCT_WITH_VIN_NUMBER,
		productsWithVinSearched: data,
	});
};

export const CLEAR_SEARCHED_PRODUCT = () => dispatch => {
	dispatch({
		type: actionTypes.CLEAR_SEARCHED_PRODUCT,
		productsWithPartNumberSearched: [],
		productsWithVinSearched: [],
		searchedEngines: [],
	});
};

export const SET_VEHICLE_TYPE = vehicleType => dispatch => {
	dispatch({
		type: actionTypes.SET_VEHICLE_TYPE,
		vehicleType,
	});
};

export const SET_SEARCHED_ENGINES = data => dispatch => {
	dispatch({
		type: actionTypes.SET_SEARCHED_ENGINES,
		searchedEngines: data,
	});
};

export const SET_REMAN_SCREEN_TYPE = (screenType = ModalScreenTypes.LOADING) => dispatch => {
	dispatch({ type: actionTypes.SET_REMAN_SCREEN_TYPE, screenType });
};

export const SET_PRODUCT_CATEGORY_LIST = (data = []) => dispatch => {
	dispatch({
		type: actionTypes.SET_PRODUCT_CATEGORY_LIST,
		productCategoryList: data,
	});
};

export const SET_VIN_CATEGORY_TITLE = (title = '') => dispatch => {
	dispatch({
		type: actionTypes.SET_VIN_CATEGORY_TITLE,
		vinSelectedCategoryTitle: title,
	});
};

export const SET_BREADCRUMB_ENGINE_ID = (enginesSectionId = null) => dispatch => {
	dispatch({
		type: actionTypes.SET_BREADCRUMB_ENGINE_ID,
		enginesSectionId,
	});
};
