import { useEffect } from 'react';

export default function useClickOutside(ref, clickOutside: (isClicked: boolean) => void, dependencies = []) {
	useEffect(() => {
		function onClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				clickOutside(true);
			}
		}

		document.addEventListener('mousedown', onClickOutside);
		return () => {
			document.removeEventListener('mousedown', onClickOutside);
		};
	}, [ref, ...dependencies]);
}
