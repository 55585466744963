import IncidentBanner from '@Shared/header/IncidentManagementBanner/items/IncidentBanner';
import LeitstandBanner from '@Shared/header/IncidentManagementBanner/items/LeitstandBanner';
import { NotificationsTypes } from '@Constants/notifications';

const IncidentManagementBannerItems = ({ bannerData, onReadMore }) => {
	const getBannerItem = (notificationItem, index) => {
		switch (notificationItem.type) {
			case NotificationsTypes.INCIDENT:
				return <IncidentBanner key={index} notificationItem={notificationItem} onReadMore={onReadMore} />;
			case NotificationsTypes.LEITSTAND:
				return <LeitstandBanner key={index} notificationItem={notificationItem} onReadMore={onReadMore} />;
			default:
				return null;
		}
	};

	return (
		<div className="disp-grid grid--item-gap-2">{bannerData.map((item, index) => getBannerItem(item, index))}</div>
	);
};

export default IncidentManagementBannerItems;
