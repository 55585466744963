import { useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@Redux';
import { GET_DIGITAL_SERVICE_BOOKLET_STATUS } from '@Reducers/user/actions';
import { DSBStatus } from '@Constants/digital-service-booklet';

export const useDSB = () => {
	const isDsbAvailable = useSelector<RootState, boolean>(state => state.configuration.featureConfiguration.dsb);

	if (!isDsbAvailable) {
		return {
			status: null,
			dsbActivated: false,
			reload: () => new Promise(noop),
		};
	}

	const dispatch: AppDispatch = useDispatch();
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);
	const digitalServiceBooklet = useSelector<RootState, any>(state => state.user.digitalServiceBooklet);
	const [status, setStatus] = useState<DSBStatus>(DSBStatus.CHECKING);

	const reloadDSB = () => {
		return isUserLoggedIn ? dispatch(GET_DIGITAL_SERVICE_BOOKLET_STATUS()) : new Promise(noop);
	};

	const getStatus = () => {
		switch (true) {
			case digitalServiceBooklet.loading:
				return DSBStatus.CHECKING;
			case digitalServiceBooklet.serviceError:
				return DSBStatus.SERVICE_ERROR;
			case digitalServiceBooklet.hasActiveLicense:
				return DSBStatus.ACTIVATED;
			default:
				return DSBStatus.NOT_ACTIVATED;
		}
	};

	useEffect(() => {
		if (!digitalServiceBooklet && isUserLoggedIn && !digitalServiceBooklet?.loading) {
			dispatch(GET_DIGITAL_SERVICE_BOOKLET_STATUS());
		}
	}, []);

	useEffect(() => {
		if (digitalServiceBooklet) setStatus(getStatus());
	}, [digitalServiceBooklet]);

	return {
		status,
		dsbActivated: status === DSBStatus.ACTIVATED,
		reload: reloadDSB,
	};
};
