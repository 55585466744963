import requestData from '@Network/index';
import { initialCatalogueFilterOptionsState } from '@Constants/webparts';

export function getDivision(preferredLanguage = '') {
	return requestData('GET', 'DIVISION', { preferredLanguage })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getSeriesAndModels(areaId, vehicleDivisions: string[], preferredLanguage = '') {
	return requestData('GET', 'SERIES_AND_MODELS', {
		interpolate: { areaId },
		params: { name: 'vehicleDivision', values: vehicleDivisions },
		preferredLanguage,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getCatalogWithAggregates(modelId, preferredLanguage = '') {
	return requestData('GET', 'GET_CATALOG_WITH_AGGREGATES', {
		interpolate: { modelId },
		preferredLanguage,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartMainGroups(
	fin: string,
	aggregateModelId: string,
	aggregateProductId: string,
	catalogProductId: string,
	preferredLanguage: string,
	filterOptions = initialCatalogueFilterOptionsState
) {
	return requestData('GET', 'GET_PART_MAIN_GROUPS', {
		data: {
			fin,
			aggregateModelId,
			aggregateProductId,
			catalogProductId,
			...filterOptions,
		},
		preferredLanguage,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartSubGroups(
	mainGroupId,
	modelId, // TODO: Aggregate will be added AggregateModelId
	productId,
	preferredLanguage,
	fin?,
	filterOptions = initialCatalogueFilterOptionsState
) {
	return requestData('GET', 'GET_PART_SUB_GROUPS', {
		interpolate: { mainGroupId },
		preferredLanguage,
		data: { modelId, productId, fin: fin || '', ...filterOptions },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartSubGroupsV2(
	mainGroupId,
	modelId, // TODO: Aggregate will be added AggregateModelId
	productId, // TODO: Aggregate will be added AggregateProductId
	catalogProductId,
	preferredLanguage,
	fin?,
	filterOptions = initialCatalogueFilterOptionsState
) {
	return requestData('GET', 'GET_PART_SUB_GROUPS', {
		interpolate: { mainGroupId },
		preferredLanguage,
		data: { catalogProductId, modelId, productId, fin: fin || '', ...filterOptions },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getCataloguePackages(vin, mainGroupId, preferredLanguage) {
	return requestData('GET', 'PACKAGES_BY_MAIN_GROUP', {
		preferredLanguage,
		data: { finOrVin: vin, mainGroupId },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartsPackages(vin, partNumbers) {
	return requestData('GET', 'PACKAGES_BY_PARTS', {
		data: { finOrVin: vin },
		params: { name: 'partNumbers', values: partNumbers },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}
