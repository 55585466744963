import ISPTooltip, { TooltipPositions, TooltipThemes } from '@isp/tooltip';

const PartAvailabilityTooltip = ({ id, hint, classNames = '' }) => (
	<ISPTooltip
		theme={TooltipThemes.DARK}
		place={TooltipPositions.BOTTOM}
		id={id}
		getContent={() => {
			return <p className={classNames}>{hint}</p>;
		}}
		className="p-2"
	/>
);

export default PartAvailabilityTooltip;
