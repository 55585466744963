import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Icon, { IconType, IconSize, IconVariant } from '@isp/icon';
import Modal, { ModalSize } from '@isp/modal';
import { useTranslation } from '@Lib/i18n';
import {
	SET_RECENT_ORDERS_FILTER_AVAILABLE,
	SET_VEHICLE_LIST_LAST_LOADED_INDEX,
	SET_VEHICLE_LIST_PAGE_INDEX,
	DELETE_VEHICLE,
	GET_FILTERED_VEHICLE_LIST,
	SET_DELETED_VEHICLE_VIN,
} from '@Reducers/vehicle/actions';
import { TOGGLE_DELETE_VEHICLE_MODAL } from '@Reducers/modals/actions';
import { AppDispatch, RootState } from '@Redux';
import routes, { getRoutesAs } from '@Routes';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { ToBeDeletedVehicleInformation } from '@Reducers/vehicle/models';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import './index.scss';

const DeleteVehicleModal = () => {
	const { t } = useTranslation(['common', 'vehicles']);
	const dispatch: AppDispatch = useDispatch();
	const vehicleInfo = useSelector<RootState, ToBeDeletedVehicleInformation>(
		state => state.modals.vehicleInfoToBeDeleted
	);
	const { vehicleImage, modelName, customerName, vinNumber, licensePlate } = vehicleInfo;
	const [error, showError] = useState(false);
	const router = useRouter();
	const { market } = router.query;

	const next = () => {
		showError(false);
		dispatch(DELETE_VEHICLE(vinNumber))
			.then(() => {
				dispatch(SET_VEHICLE_LIST_LAST_LOADED_INDEX());
				dispatch(SET_VEHICLE_LIST_PAGE_INDEX());
				dispatch(GET_FILTERED_VEHICLE_LIST());
				dispatch(TOGGLE_DELETE_VEHICLE_MODAL());
				router.push(routes.HOME, getRoutesAs(routes.HOME, { market }), { scroll: true, shallow: true });
			})
			.catch(() => showError(true))
			.finally(() => {
				dispatch(SET_RECENT_ORDERS_FILTER_AVAILABLE());
				dispatch(SET_DELETED_VEHICLE_VIN(vinNumber));
			});
	};

	const getPrimaryButtonText = () => {
		return error ? t('common:close') : t('common:cancel');
	};

	const getSecondaryButtonAction = () => {
		return error ? dispatch(TOGGLE_DELETE_VEHICLE_MODAL()) : next();
	};

	const getSecondaryButtonText = () => {
		return error ? t('common:close') : t('vehicles:delete-vehicle-button');
	};

	const handleCloseClicked = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		dispatch(TOGGLE_DELETE_VEHICLE_MODAL());
	};

	return (
		<Modal
			show
			title={t('vehicles:delete-from-vehicle-list')}
			titleClassname="fs-22 DaimlerCS-Bold"
			onClose={handleCloseClicked}
			size={ModalSize.SM}
			footerPrompt={!error}
			footerAction={error}
			primaryButtonId={getComponentId([Pages.MODAL, Components.VEHICLE_DELETION, Buttons.CLOSE])}
			primaryButtonAction={handleCloseClicked}
			primaryButtonText={getPrimaryButtonText()}
			secondaryButtonId={getComponentId([Pages.MODAL, Components.VEHICLE_DELETION, Buttons.SUBMIT])}
			secondaryButtonAction={getSecondaryButtonAction}
			secondaryButtonText={getSecondaryButtonText()}
			dataQAPrimaryButton={DATA_QA.DELETE_VEHICLE_CANCEL_BUTTON}
			dataQASecondaryButton={DATA_QA.DELETE_VEHICLE_CONTINUE_BUTTON}
		>
			{!error && (
				<div className="disp-flex m-t-5">
					<img src={vehicleImage} alt="" width={175} height={98} />
					<div className="color-brownish-grey fs-16">
						<p className="DaimlerCS-Bold color-black-two">{modelName}</p>
						<p>{vinNumber}</p>
						<p className="delete-vehicle__content">{customerName}</p>
						<p className="delete-vehicle__content">{licensePlate}</p>
					</div>
				</div>
			)}
			{error && (
				<div className="disp-flex">
					<Icon className="m-r-4" type={IconType.WARNING} size={IconSize.HUGE} variant={IconVariant.BLOODRED} />
					<div>
						<p className="fs-38 DaimlerCAC-Regular color-black-two">{t('vehicles:something-went-wrong')}</p>
						<p className="fs-14 DaimlerCAC-Regular">{t('vehicles:vehicle-cannot-deleted')}</p>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default DeleteVehicleModal;
