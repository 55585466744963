import dayjs from 'dayjs';
import { getReadNews, postReadNews } from '@Apis/customer';
import { StorageKeys, compareVersions, getLatestVersion } from '@Helpers/storage';
import { TOGGLE_NEW_FEATURES_MODAL } from '@Reducers/modals/actions';
import { actionTypes } from '@Reducers/sections/actionTypes';
import storage from '@Storage';
import { FooterMenuExternalUrl, FooterOrder, Newsletter, getNewsletter } from '@Umbraco/footer';
import getImprintItems, { ImprintItem } from '@Umbraco/imprint';
import { getMenu, getSidebar } from '@Umbraco/menu';
import { NewsItem, getNews } from '@Umbraco/news';
import { getReleases } from '@Umbraco/releases';
import { getLuckyDrawContent } from '@Umbraco/lucky-draw';
import { NEW_FEATURE_MODAL_REMIND_LATER_INTERVAL } from '@Constants/common';

export const SET_CAMPAIGNS = (campaigns: boolean) => dispatch => {
	dispatch({
		type: actionTypes.SET_CAMPAIGNS,
		campaigns,
	});
};

export const GET_NEWS = (market, language, isUserLoggedin) => dispatch => {
	getNews(market, language, isUserLoggedin).then(news => {
		dispatch({
			type: actionTypes.SET_NEWS,
			news,
		});
	});
};

export const SET_NEWS = (news: NewsItem[]) => dispatch => {
	dispatch({
		type: actionTypes.SET_NEWS,
		news,
	});
};

export const SET_NEWSLETTER = (newsletter: Newsletter) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_NEWSLETTER,
		footerContent: { ...getState().sections.footerContent, newsletter },
	});
};

export const SET_FOOTER_MENU_EXTERNAL_URLS = (footerMenuExternalUrls: FooterMenuExternalUrl[]) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.SET_FOOTER_MENU_EXTERNAL_URLS,
		footerContent: { ...getState().sections.footerContent, footerMenuExternalUrls },
	});
};

export const SET_FOOTER_GROUPS = (footerGroups: ImprintItem[]) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_FOOTER_GROUPS,
		footerContent: { ...getState().sections.footerContent, footerGroups },
	});
};

export const SET_FOOTER_ORDER = (footerOrder: FooterOrder) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_FOOTER_ORDER,
		footerContent: { ...getState().sections.footerContent, footerOrder },
	});
};

export const SET_MENU = menuItems => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_MENU,
		headerContent: { ...getState().sections.headerContent, menuItems },
	});
};

export const GET_SIDEBAR_CONTENT = (market, language) => dispatch => {
	dispatch({
		type: actionTypes.GET_SIDEBAR_CONTENT,
		sidebar: [],
		sidebarLoading: true,
	});
	getSidebar(market as string, language)
		.then(sidebar => {
			dispatch({
				type: actionTypes.GET_SIDEBAR_CONTENT,
				sidebar,
				sidebarLoading: false,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_SIDEBAR_CONTENT,
				sidebar: [],
				sidebarLoading: false,
			});
		});
};

export const SET_PLATFORM_SEARCH_PREVIOUS_ROUTE = route => dispatch => {
	dispatch({ type: actionTypes.SET_PLATFORM_SEARCH_PREVIOUS_ROUTE, platformSearchPrevRoute: route });
};

export const GET_LATEST_RELEASE = (market, language) => dispatch => {
	return getReleases(market, language).then(releases => {
		const latestRelease = getLatestVersion(releases.releases);
		dispatch({
			type: actionTypes.GET_LATEST_RELEASE,
			latestRelease,
		});

		const appVersion = storage.getItem(StorageKeys.APP_VERSION) || '0';
		const isNewFeatureAvailable = compareVersions(latestRelease.version || '0', appVersion);
		const remindMeLaterStartTime = sessionStorage.getItem(
			StorageKeys.STORAGE_KEY_FEATURE_MODAL_REMIND_LATER_START_TIME
		);

		if (remindMeLaterStartTime) {
			if (dayjs().diff(remindMeLaterStartTime, 'hour') >= NEW_FEATURE_MODAL_REMIND_LATER_INTERVAL)
				dispatch(TOGGLE_NEW_FEATURES_MODAL(isNewFeatureAvailable));
		} else {
			dispatch(TOGGLE_NEW_FEATURES_MODAL(isNewFeatureAvailable));
		}
	});
};

export const GET_NEWS_LETTER = (market, language) => dispatch => {
	getNewsletter(market, language).then(newsletter => {
		dispatch(SET_NEWSLETTER(newsletter));
	});
};

export const GET_FOOTER_GROUPS = (market, language) => dispatch => {
	getImprintItems(market, language).then(footerGroups => {
		dispatch(SET_FOOTER_GROUPS(footerGroups));
	});
};

export const GET_MENU_ITEMS = (market, language) => dispatch => {
	getMenu(market, language).then(response => {
		dispatch(SET_MENU(response?.children || []));
	});
};

export const MARK_READ_NEWS = (...newsIds) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.MARK_READ_NEWS,
		readNews: [...(getState().sections?.readNews || []), ...newsIds],
	});
	return postReadNews([...newsIds]);
};

export const GET_READ_NEWS = () => dispatch => {
	dispatch({
		type: actionTypes.GET_READ_NEWS,
		readNews: [],
		readNewsLoading: true,
	});
	return getReadNews().then(res => {
		dispatch({
			type: actionTypes.GET_READ_NEWS,
			readNews: res?.newsIds || [],
			readNewsLoading: false,
		});
	});
};

export const GET_LUCKY_DRAW_CONTENT = (market, language) => dispatch => {
	return getLuckyDrawContent(market, language).then(res => {
		dispatch({
			type: actionTypes.GET_LUCKY_DRAW_CONTENT,
			luckyDrawContent: res,
		});
		return res;
	});
};

export const SET_STICKY_TOOLBAR_TOP_DISTANCE = (stickyToolbarTopDistance: number) => dispatch => {
	dispatch({
		type: actionTypes.SET_STICKY_TOOLBAR_TOP_DISTANCE,
		stickyToolbarTopDistance,
	});
};
