import Button, { ButtonVariant } from '@isp/button';
import { login } from '@User-operations';
import { useTranslation } from '@Lib/i18n';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import { Buttons, Components, EventActions, EventCategories, Pages, getComponentId } from '@Constants/google-analytics';
import { Events } from '@Constants/common';
import ModuleHeader from '../Shared/ModuleHeader';
import RegisterButton from '../Shared/RegisterButton';

const RegisterModule = ({ data, registrationRef }) => {
	const { t } = useTranslation(['common']);

	const onLoginClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.LINK,
			action: EventActions.LOGIN,
		});
		login();
	};

	return (
		<div className="home__modules w-100-p home__modules__register p-20 m-auto">
			<ModuleHeader title={data?.title} note={data?.note} />
			<div className="home__modules home__modules__register__buttons grid--item-gap-2 m-auto text-center m-t-3">
				<Button
					id={getComponentId([Pages.HOME, Components.LOGIN])}
					onClick={onLoginClick}
					className="p-h-4 color-black-two fs-16"
					variant={ButtonVariant.FLAT}
				>
					{t('common:login')}
				</Button>
				<RegisterButton
					registrationRef={registrationRef}
					id={getComponentId([Components.REGISTER, Components.MODAL, Buttons.REGISTER])}
				/>
			</div>
		</div>
	);
};

export default RegisterModule;
