export const QUESTION_PER_PAGE = 6;
export const DEFAULT_ACTIVE_QUESTION_INDEX = -1;

export const CATEGORY_PAGE_MAPPING = {
	ACCOUNT: 'account',
	MB_GENUINE_PARTS_AND_PRODUCTS: 'mercedes-benz-genuine-parts-and-products',
	SOFTWARE: 'software',
	WORKSHOP_SOLUTIONS: 'software',
	PAYMENT_AND_BILLING: 'payment-billing',
	ORDERS: 'order-cancellation',
	TECHNICAL_ISSUES: 'technical-problems',
	SERMI: 'sermi-authentication',
};

export enum UsersAnswer {
	YES = 'Yes',
	NO = 'No',
	NOT_ANSWERED = 'NOT_ANSWERED',
}

export enum Categories {
	ACCOUNT = 'ACCOUNT',
	MB_GENUINE_PARTS_AND_PRODUCTS = 'MB_GENUINE_PARTS_AND_PRODUCTS',
	SOFTWARE = 'SOFTWARE',
	PAYMENT_AND_BILLING = 'PAYMENT_AND_BILLING',
	ORDERS = 'ORDERS',
	TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
	MOST_ASKED_QUESTIONS = 'MOST_ASKED_QUESTIONS',
	FIRST_STEPS = 'FIRST_STEPS',
}

export enum MessageColor {
	WHITE = 'WHITE',
	GRAY = 'GRAY',
	BLUE = 'BLUE',
}

export enum RouteAvailableSoftwareProducts {
	WIS = 'WIS',
	ASRA = 'ASRA',
}
