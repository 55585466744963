import { useResize, useScroll } from './useEvent';

export const useAppHeight = () => {
	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty('--app-height', `${window.innerHeight}px`);
	};

	useResize(appHeight, { init: true });
	useScroll(appHeight);
};
