import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import NextI18Next from '@Lib/i18n';
import { getCanonicalLink } from '@Helpers/common/getCanonicalLink';
import { getMetaSettings } from '@Helpers/common/getMetaSettings';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { withLayout } from '@Lib/withLayout';
import { RootState } from '@Redux';
import Home from '@Partials/NewHome';
import { getHome } from '@Umbraco/home';
import { LAYOUT_NAMESPACES } from '@Layouts/main';
import { removeHashFromURL } from '@Helpers/QueryString';
import { SidebarTabs } from '@Constants/menu';
import { FeedbackBackground } from '@Constants/home';
import { LayoutBackgrounds, MetaSettingPages } from '@Constants/common';

const LoggedInContent = dynamic(() => import('@Partials/NewHome/LoggedInContent'), { ssr: false });

const HomePage = ({ homeData }) => {
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);

	useEffect(() => {
		removeHashFromURL();
	}, []);

	return (
		<>
			{isUserLoggedIn && <LoggedInContent />}
			{!isUserLoggedIn && <Home {...homeData} />}
		</>
	);
};

HomePage.getInitialProps = async ({ req, query }) => {
	const canonicalURL = getCanonicalLink(req);
	const language = req && req.language ? req.language : geti18nLanguage(NextI18Next.i18n.language);
	const { market } = query;
	const metaSettings = await getMetaSettings(MetaSettingPages.HOME, market, language);
	const homeData = await getHome(market, language);
	return {
		namespacesRequired: [...LAYOUT_NAMESPACES, 'products'],
		canonicalURL,
		metaSettings,
		market,
		homeData,
	};
};

export default withLayout(HomePage, {
	activeTab: SidebarTabs.VEHICLE_LIST,
	showSidebar: true,
	showBackToVehicles: false,
	showFeedback: FeedbackBackground.WHITE_SMOKE,
	background: LayoutBackgrounds.WHITE_SMOKE,
});
