import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from '@isp/icon';
import Loading, { LoadingSizes } from '@isp/loading';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import FootnoteItem from './FootnoteItem';

interface PartItemPackageProps {
	packageLoading: boolean;
	packageAvailable: boolean;
	partPackageError: boolean;
	handlePartsPackage: () => void;
	partNumber: string;
}

const PartItemPackage: FC<PartItemPackageProps> = ({
	packageLoading,
	packageAvailable,
	partPackageError,
	handlePartsPackage,
	partNumber,
}) => {
	const { t } = useTranslation(['vehicles']);

	return (
		<div className="disp-flex train__center">
			{packageLoading && <Loading size={LoadingSizes.MICRO} />}
			{packageAvailable && !partPackageError && (
				<FootnoteItem
					onClick={handlePartsPackage}
					content={t('vehicles:included-in-packages')}
					iconType={IconType.DELIVERY_LIGHT}
					id={getComponentId(
						[Pages.VEHICLE, Components.PART_PACKAGES, Buttons.OPEN, 'part-package', partNumber],
						true,
						true
					)}
				/>
			)}
		</div>
	);
};

export default PartItemPackage;
