import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import min from 'lodash/min';
import find from 'lodash/find';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import ISPSidebar, { SidebarTab } from '@isp/sidebar';
import { useTranslation } from '@Lib/i18n';
import { getRoutesAs, isRouteCatalog } from '@Routes';
import { RootState } from '@Redux';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import { useResize, useScroll } from '@Helpers/hooks/useEvent';
import { useNewsNotification } from '@Helpers/news';
import { goToWheelsURL, getWheelsURL } from '@Helpers/ExternalURLs';
import useInterval from '@Helpers/hooks/useInterval';
import useCampaigns from '@Helpers/hooks/useCampaigns';
import { Components, EventCategories, EventLocation, getComponentId, Pages } from '@Constants/google-analytics';
import { CustomEvents, Events, MS_IN_SEC, SIDEBAR_EXPAND_LIMIT, STICKY_TOOLBAR_HEIGHT } from '@Constants/common';
import { SidebarTabs, SIDEBAR_FALLBACK, SidebarGroups, SIDEBAR_ANIMATION_DELAY, ASPSidebarTabs } from '@Constants/menu';
import { SearchedVehicleStatus } from '@Constants/catalog';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import './index.scss';

const Sidebar = ({ activeTab = null, sidebarList = null, showSidebar }) => {
	const { t } = useTranslation(['vehicles']);
	const router = useRouter();
	const { vin } = router.query;
	const divRef = useRef(null);
	const sidebarActionRef = useRef(null);
	const [debouncedShowSideBar] = useDebounce(showSidebar, SIDEBAR_ANIMATION_DELAY);
	const [sidebarExpanded, setSidebarExpanded] = useState(false);
	const tabs = sidebarList || useSelector<RootState, Array<any>>(state => state.sections.sidebar);
	const loading = !sidebarList && useSelector<RootState, boolean>(state => state.sections.sidebarLoading);
	const readNewsLoading = useSelector<RootState, boolean>(state => state.sections.readNewsLoading);
	const searchedVehicleStatus = useSelector<RootState, SearchedVehicleStatus>(
		state => state.catalogue.searchedVehicleStatus
	);
	const unreadNewsExist = useNewsNotification();
	const campaigns = useCampaigns();

	const getSidebarFallback = () => {
		return SIDEBAR_FALLBACK.filter(tab => tab.identifier !== SidebarTabs.RETAILER);
	};

	const getSidebarTabs = () => {
		let group = SidebarGroups.HOME;
		let list = tabs;
		if (!tabs || isEmpty(tabs)) {
			list = getSidebarFallback();
		}

		tabs?.forEach(tab => {
			if (tab.identifier === SidebarTabs.NEWS) tab.notification = unreadNewsExist && !readNewsLoading;
			else if (tab.identifier === SidebarTabs.WHEELS) {
				tab.url = getWheelsURL();
				tab.action = goToWheelsURL;
			}
		});

		if (activeTab) {
			const activeTabItem = find(list, item => item.identifier === activeTab);
			group = get(activeTabItem, 'group');
		}

		return list.filter(
			item =>
				(item.identifier !== SidebarTabs.CATALOGUE_CAMPAIGNS || !isEmpty(campaigns)) &&
				item.group === group &&
				!isNil(item.route)
		);
	};

	const isTabDisabled = tab => {
		const noVIN =
			(tab.identifier === SidebarTabs.CATALOGUE_OVERVIEW ||
				tab.identifier === SidebarTabs.PACKAGES ||
				tab.identifier === ASPSidebarTabs.PACKAGES) &&
			!vin;
		const nonSavedVIN =
			tab.identifier === SidebarTabs.CATALOGUE_OVERVIEW && searchedVehicleStatus === SearchedVehicleStatus.NONSAVED;
		return noVIN || nonSavedVIN;
	};

	const getTabTooltipText = tab => {
		switch (true) {
			case tab.identifier === SidebarTabs.CATALOGUE_OVERVIEW && isTabDisabled(tab):
				return t('vehicles:details-tab-available-for-saved-vehicles');
			case tab.identifier === ASPSidebarTabs.PACKAGES && isTabDisabled(tab):
				return t('vehicles:packages-tab-asp-user');
			case tab.identifier === SidebarTabs.PACKAGES && isTabDisabled(tab):
				return t('vehicles:packages-tab-available-for-saved-vehicles');
			default:
				return undefined;
		}
	};

	const navigate = async route => {
		let queryParams = {};

		if (isRouteCatalog()) {
			const { vin, model, modelName, pageSource } = router.query;

			queryParams = {
				...(vin && { vin }),
				...(model && { model }),
				...(modelName && { modelName }),
				...(pageSource && { pageSource }),
			};
		}

		await router.push(
			{
				pathname: getRoutesAs(route, router.query),
				query: queryParams,
			},
			undefined,
			{ shallow: true }
		);

		return noop;
	};

	const calcHeight = () => {
		if (!divRef.current) return;
		const dimensions = divRef.current.getBoundingClientRect();
		const parentDimensions = divRef.current.parentNode.getBoundingClientRect();
		const heightStart = max([dimensions.top, STICKY_TOOLBAR_HEIGHT]);
		const heightEnd = min([parentDimensions.bottom, window.innerHeight]);
		const sidebarHeight = `${heightEnd - heightStart}px`;

		window.dispatchEvent(new CustomEvent(CustomEvents.SIDEBAR_HEIGHT_CHANGE, { detail: { value: sidebarHeight } }));
		divRef.current.style.height = sidebarHeight;
	};

	useScroll(calcHeight);
	useResize(calcHeight);
	useInterval(calcHeight, MS_IN_SEC);

	useEffect(() => {
		calcHeight();
		if (window && window.innerWidth > SIDEBAR_EXPAND_LIMIT) {
			setSidebarExpanded(true);
			sidebarActionRef?.current?.expand();
		}
	}, []);

	useEffect(() => {
		if (debouncedShowSideBar && sidebarExpanded) sidebarActionRef?.current?.expand();
	}, [debouncedShowSideBar]);

	return (
		debouncedShowSideBar && (
			<ISPSidebar
				sidebarRef={divRef}
				loading={loading}
				expandDataQA={DATA_QA.SIDEBAR_EXPAND}
				style={{ height: 'calc(100vh - 80px)' }}
				ref={sidebarActionRef}
				onExpandClick={() => setSidebarExpanded(!sidebarExpanded)}
			>
				{getSidebarTabs().map((tab, index) => {
					const { identifier, route, action, text, url } = tab;
					return (
						<a
							style={{ textDecoration: 'none', cursor: 'auto' }}
							key={index}
							aria-label={text}
							href={url || getRoutesAs(route, router.query)}
							onClick={e => {
								e.preventDefault();
							}}
						>
							<SidebarTab
								id={getComponentId([Pages.GLOBAL, Components.SIDEBAR, identifier])}
								active={identifier === activeTab}
								disabled={isTabDisabled(tab)}
								tooltip={getTabTooltipText(tab)}
								dataQA={`${DATA_QA.SIDEBAR_TAB_PREFIX}-${identifier}`}
								// eslint-disable-next-line consistent-return
								onClick={async (e: MouseEvent) => {
									PushEventToDataLayer(Events.CLICK, {
										category: EventCategories.NAVIGATION,
										action: EventLocation.SIDEBAR,
										label: text,
									});
									PushDataToTagManagerForButtons((e.target as HTMLElement).id, window.location.href);
									if (action) return action();
									if (route !== router.pathname) return navigate(route);
								}}
								{...tab}
							/>
						</a>
					);
				})}
			</ISPSidebar>
		)
	);
};

export default Sidebar;
