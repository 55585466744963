import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Input, { InputSubThemes, InputThemes } from '@isp/input';
import { getVehicleLicensePlateVINMapping } from '@Apis/vehicle';
import { Patterns, ResponseCode, VIN_LENGTH } from '@Constants/common';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import {
	AddVehicleVINErrorMessages,
	LICENSE_PLATE_VIN_MAPPING_COUNTRIES,
	VEHICLE_FIELD_LIMITS,
} from '@Constants/vehicle';
import { Markets } from '@Constants/market';
import VINInput from './VINInput';

export const GET_VIN_DELAY = 1000;

interface AddVehicleModalContentProps {
	highlightedVehicleVIN: string;
	licensePlateNumber: string;
	clientName: string;
	model: string;
	VIN: string;
	isVINValid: boolean;
	VINFormatError: boolean;
	VINEditDisabled: boolean;
	isVINAlreadyExist: boolean;
	loadingCheckVehicle: boolean;
	loadingSaveVehicles: boolean;
	isLicensePlateAllowed: boolean;
	responseCode: ResponseCode;
	setVIN: Dispatch<SetStateAction<string>>;
	onValidVIN: (vin: any) => Promise<void>;
	onInvalidVIN: () => void;
	setClientName: Dispatch<SetStateAction<string>>;
	isVINFormatValid: (vin: any) => any;
	setLicensePlateNumber: Dispatch<SetStateAction<string>>;
	apiException: boolean;
}

const AddVehicleModalContent: FC<AddVehicleModalContentProps> = ({
	VIN,
	model,
	clientName,
	isVINValid,
	VINFormatError,
	VINEditDisabled,
	isVINAlreadyExist,
	licensePlateNumber,
	loadingCheckVehicle,
	loadingSaveVehicles,
	isLicensePlateAllowed,
	highlightedVehicleVIN,
	setVIN,
	onValidVIN,
	responseCode,
	onInvalidVIN,
	setClientName,
	isVINFormatValid,
	setLicensePlateNumber,
	apiException,
}) => {
	const [licensePlateMappedVIN, setLicensePlateMappedVIN] = useState(null);
	const { t: translate } = useTranslation(['vehicles']);
	const router = useRouter();
	const saveTimeout = useRef<any>(null);
	const { market } = router.query;
	const isLicensePlateMapping = LICENSE_PLATE_VIN_MAPPING_COUNTRIES.includes(market as Markets);
	const VINError = VIN && (isVINAlreadyExist || !isVINValid);
	const formatError = (VIN?.length <= VIN_LENGTH && !isVINFormatValid(VIN)) || VINFormatError;
	const showErrorMessage = VINError || VINFormatError || apiException;
	const inputRef = useRef(null);

	const getMessage = () => {
		switch (true) {
			case apiException:
				return AddVehicleVINErrorMessages.API_EXCEPTION;
			case formatError:
				return AddVehicleVINErrorMessages.CHECK_FORMAT;
			case isVINAlreadyExist:
				return AddVehicleVINErrorMessages.ALREADY_SAVED;
			case VIN?.length !== VIN_LENGTH || (!isVINValid && responseCode === ResponseCode.NOT_FOUND):
				return AddVehicleVINErrorMessages.INVALID;
			case !isVINValid && responseCode === ResponseCode.FORBIDDEN:
				return AddVehicleVINErrorMessages.UNSUPPORTED;
			default:
				return AddVehicleVINErrorMessages.DEFAULT;
		}
	};

	const getDescription = () => {
		switch (true) {
			case !!highlightedVehicleVIN:
				return translate('vehicles:add-vehicle-description-dsb');
			case isLicensePlateMapping:
				return translate('vehicles:add-vehicle-description-license-plate-mapping');
			case !isLicensePlateAllowed:
				return translate('vehicles:add-vehicle-description-only-vin');
			default:
				return translate('vehicles:add-vehicle-description');
		}
	};

	const setLicensePlateNumberWithControl = (plateNumber: string) => {
		if (saveTimeout) clearTimeout(saveTimeout.current);
		setLicensePlateNumber(plateNumber);
		if (isLicensePlateMapping && !isEmpty(plateNumber)) {
			const formattedLicensePlate = plateNumber.toUpperCase().replace(/\s/g, '');
			saveTimeout.current = setTimeout(
				() =>
					getVehicleLicensePlateVINMapping(formattedLicensePlate).then(res => {
						setLicensePlateMappedVIN(res);
					}),
				GET_VIN_DELAY
			);
		}
	};

	const getInputField = renderOrderCondition => {
		return renderOrderCondition ? (
			<Input
				disabled={loadingSaveVehicles}
				value={licensePlateNumber}
				theme={InputThemes.FLAT}
				subTheme={InputSubThemes.GRAY}
				label={translate('vehicles:license-plate')}
				placeholder={translate('vehicles:license-plate')}
				onChange={value => {
					setLicensePlateNumberWithControl(value.substring(0, VEHICLE_FIELD_LIMITS.PLATE_NUMBER));
				}}
				pattern={Patterns.VEHICLE_REGISTRATION_NUMBER}
				dataQA={DATA_QA.ADD_VEHICLE_MODAL_LICENSE_PLATE_INPUT}
			/>
		) : (
			<VINInput
				isLicensePlateAllowed={isLicensePlateAllowed}
				VINEditDisabled={VINEditDisabled}
				loadingSaveVehicles={loadingSaveVehicles}
				VIN={VIN}
				setVIN={setVIN}
				onInvalidVIN={onInvalidVIN}
				onValidVIN={onValidVIN}
				loadingCheckVehicle={loadingCheckVehicle}
				showErrorMessage={showErrorMessage}
				inputRef={inputRef}
				getMessage={getMessage}
				model={model}
			/>
		);
	};

	useEffect(() => {
		if (highlightedVehicleVIN) {
			setVIN(highlightedVehicleVIN);
			onValidVIN(highlightedVehicleVIN);
		}
	}, []);

	useEffect(() => {
		if (licensePlateMappedVIN) {
			setVIN(licensePlateMappedVIN);
			onValidVIN(licensePlateMappedVIN);
		}
	}, [licensePlateMappedVIN]);

	return (
		<div className="add-vehicle__container">
			<p className="fs-38 DaimlerCAC-Regular color-black-two" data-qa={DATA_QA.ADD_VEHICLE_MODAL_TITLE}>
				{translate('vehicles:add-vehicle')}
			</p>
			<p className="fs-14 DaimlerCS-Regular color-black-two">{getDescription()}</p>
			<div className={classNames({ 'add-vehicle__content': isLicensePlateAllowed })}>
				{getInputField(isLicensePlateAllowed && isLicensePlateMapping)}
				{isLicensePlateAllowed && (
					<>
						{getInputField(!isLicensePlateMapping)}
						<Input
							disabled={loadingSaveVehicles}
							theme={InputThemes.FLAT}
							subTheme={InputSubThemes.GRAY}
							label={translate('vehicles:vehicle-details-customer')}
							placeholder={translate('vehicles:vehicle-details-customer')}
							value={clientName}
							pattern={Patterns.NAME_OR_SURNAME}
							onChange={value => {
								setClientName(value.substring(0, VEHICLE_FIELD_LIMITS.NAME));
							}}
							dataQA={DATA_QA.ADD_VEHICLE_MODAL_CUSTOMER_NAME_INPUT}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default AddVehicleModalContent;
