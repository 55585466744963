import { FC, useEffect, useState } from 'react';
import router from 'next/router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@Redux';
import routes, { getRoutesAs } from '@Routes';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@Lib/i18n';
import Modal, { ModalSize } from '@isp/modal';
import Button, { ButtonVariant } from '@isp/button';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import Loading, { LoadingSizes } from '@isp/loading';
import {
	TOGGLE_ADDITIONAL_INFO_NOTIFICATION,
	TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL,
	TOGGLE_ADD_VEHICLE_MODAL,
	TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL,
} from '@Reducers/modals/actions';
import { SET_SEARCHED_VEHICLE_STATUS } from '@Reducers/catalogue/actions';
import { getVehicleFOCodes, validateOrganizationVehicle } from '@Apis/vehicle';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { refreshCatalogueVinSave } from '@Helpers/vehicle';
import { GET_VEHICLE_INFO, SAVE_VEHICLE } from '@Reducers/vehicle/actions';
import { Markets } from '@Constants/market';
import { Patterns, ResponseCode } from '@Constants/common';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import { AddVehicleResponseCode, LICENSE_PLATE_NOT_ALLOWED_COUNTRIES } from '@Constants/vehicle';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { SearchedVehicleStatus } from '@Constants/catalog';
import { ADDITIONAL_INFO_NOTIFICATION_DISPLAY_TIMEOUT } from '@Constants/shop';
import Content from './Content';
import './index.scss';

const AddVehicleModal: FC = () => {
	const [VIN, setVIN] = useState('');
	const [model, setModel] = useState('');
	const [licensePlateNumber, setLicensePlateNumber] = useState('');
	const [clientName, setClientName] = useState('');
	const [isVINValid, setIsVINValid] = useState(false);
	const [loadingCheckVehicle, setLoadingCheckVehicle] = useState(false);
	const [loadingSaveVehicles, setLoadingSaveVehicles] = useState(false);
	const [errorSaveVehicles, setErrorSaveVehicles] = useState(false);
	const [isVINAlreadyExist, setIsVinAlreadyExist] = useState(false);
	const [isDatacardExist, setIsDatacardExist] = useState(true);
	const [VINResponse, setVINResponse] = useState(ResponseCode.NOT_FOUND);
	const [VINFormatError, setVINFormatError] = useState(false);
	const [apiException, setApiException] = useState(false);

	const VINEditDisabled = useSelector<RootState, boolean>(state => state.modals.VINEditDisabled);
	const highlightedVehicleVIN = useSelector<RootState, string>(state => state.modals.highlightedVehicleVIN);
	const { t } = useTranslation(['common', 'vehicles']);
	const dispatch: AppDispatch = useDispatch();

	const { market } = router.query;
	const isLicensePlateAllowed = !LICENSE_PLATE_NOT_ALLOWED_COUNTRIES.includes(market as Markets);

	const handleSpecialModification = () => {
		getVehicleFOCodes(VIN).then(foCodes => {
			if (foCodes.length > 0) {
				dispatch(TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL(true, foCodes));
			}
		});
	};

	const handleSaveSuccess = res => {
		handleSpecialModification();
		dispatch(SET_SEARCHED_VEHICLE_STATUS(SearchedVehicleStatus.SAVED));
		dispatch(TOGGLE_ADD_VEHICLE_MODAL(res));
		if (!isVINAlreadyExist && !errorSaveVehicles) {
			setTimeout(() => {
				dispatch(TOGGLE_ADDITIONAL_INFO_NOTIFICATION(<InfoForNotification model={model} VIN={VIN} />));
			}, ADDITIONAL_INFO_NOTIFICATION_DISPLAY_TIMEOUT);
			refreshCatalogueVinSave();
		}
	};

	const handleSaveFail = err => {
		if (get(err, 'response.data.responseCode') === AddVehicleResponseCode.CONFLICT) {
			setIsVinAlreadyExist(true);
		}
		setErrorSaveVehicles(true);
	};

	const save = async () => {
		const vinNumberTrimmed: string = VIN.trim();
		setLoadingSaveVehicles(true);
		dispatch(SAVE_VEHICLE(vinNumberTrimmed, { plateNumber: licensePlateNumber, customerFullName: clientName }))
			.then(handleSaveSuccess)
			.catch(handleSaveFail)
			.finally(() => setLoadingSaveVehicles(false));
	};

	const isVINFormatValid = vin => {
		return vin.trim().match(Patterns.VIN_LENGTH_PATTERN);
	};

	const onValidVIN = async vin => {
		const vinEmpty = isEmpty(vin);
		let isVehicleDatacardExist = true;
		if (!vinEmpty) {
			setLoadingCheckVehicle(true);

			const validationResult = await validateOrganizationVehicle(vin.trim()).finally(() =>
				setLoadingCheckVehicle(false)
			);

			const { responseCode } = validationResult;
			setIsVINValid(false);
			setVINFormatError(false);
			setIsVinAlreadyExist(false);
			setApiException(false);
			setVINResponse(responseCode);

			switch (true) {
				case ResponseCode.EXTERNAL_API_EXCEPTION === responseCode:
					setApiException(true);
					break;
				case [ResponseCode.NOT_FOUND, ResponseCode.FORBIDDEN].includes(responseCode):
					setIsVINValid(false);
					break;
				case ResponseCode.CONFLICT === responseCode:
					setIsVinAlreadyExist(true);
					break;
				case ResponseCode.DATACARD_NOT_FOUND === responseCode:
					isVehicleDatacardExist = false;
					setIsDatacardExist(isVehicleDatacardExist);
					break;
				default:
					setIsVINValid(true);
					break;
			}

			if ([ResponseCode.SUCCESS, ResponseCode.DATACARD_NOT_FOUND].includes(responseCode)) {
				setLoadingCheckVehicle(true);
				dispatch(GET_VEHICLE_INFO(vin.trim(), isVehicleDatacardExist))
					.then(response => {
						if (response.responseCode === AddVehicleResponseCode.SUCCESS) {
							setIsVINValid(true);
							setIsVinAlreadyExist(false);
							setModel(response.data);
						}
					})
					.catch(() => setIsVINValid(false))
					.finally(() => setLoadingCheckVehicle(false));
			}
		}
	};

	const onInvalidVIN = () => {
		setIsVinAlreadyExist(false);
		setIsVINValid(false);
		setModel('');
	};

	const getHint = () => (
		<>
			{errorSaveVehicles && <p className="m-h-1 color-blood-red fs-12">{t('vehicles:add-vehicle-error')}</p>}
			<div>
				<span className="color-blood-red fs-12">* </span>
				<label className="color-black-two">{t('vehicles:these-fields-must-be-completed')}</label>
			</div>
		</>
	);

	const isSecondaryButtonDisabled = () => {
		const loading = loadingCheckVehicle || loadingSaveVehicles;

		return loading || isEmpty(VIN) || isVINAlreadyExist || !isVINValid || VINFormatError;
	};

	const getSaveButtonContent = () => {
		return loadingSaveVehicles ? <Loading size={LoadingSizes.MICRO} /> : t('common:save');
	};

	const getModalSize = () => {
		if (isLicensePlateAllowed) return ModalSize.MD;
		return errorSaveVehicles ? ModalSize.AUTO : ModalSize.SM;
	};

	const handleOnClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		dispatch(TOGGLE_ADD_VEHICLE_MODAL());
	};

	const handleOnSave = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		if (!isDatacardExist) dispatch(TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL(VIN.trim(), save));
		else save();
	};

	useEffect(() => {
		if (!VIN) {
			setIsVinAlreadyExist(false);
			setModel('');
			setVINFormatError(false);
		}
	}, [VIN]);

	return (
		<Modal
			show
			hint={getHint()}
			footerPrompt
			onClose={handleOnClose}
			size={getModalSize()}
			primaryButtonAction={handleOnClose}
			primaryButtonText={t('common:cancel')}
			primaryButtonId={getComponentId([Pages.MODAL, Components.VEHICLE_ADDITION, Buttons.CANCEL])}
			secondaryButtonAction={handleOnSave}
			secondaryButtonText={getSaveButtonContent()}
			secondaryButtonId={getComponentId([Pages.MODAL, Components.VEHICLE_ADDITION, Buttons.SUBMIT])}
			isSecondaryButtonDisabled={isSecondaryButtonDisabled()}
			dataQASecondaryButton={DATA_QA.ADD_VEHICLE_MODAL_SAVE_BUTTON}
		>
			<Content
				VIN={VIN.trim()}
				setLicensePlateNumber={setLicensePlateNumber}
				setClientName={setClientName}
				setVIN={setVIN}
				responseCode={VINResponse}
				licensePlateNumber={licensePlateNumber}
				clientName={clientName}
				onValidVIN={onValidVIN}
				onInvalidVIN={onInvalidVIN}
				model={model}
				loadingSaveVehicles={loadingSaveVehicles}
				loadingCheckVehicle={loadingCheckVehicle}
				isVINAlreadyExist={isVINAlreadyExist}
				isVINValid={isVINValid}
				VINFormatError={VINFormatError}
				isVINFormatValid={isVINFormatValid}
				isLicensePlateAllowed={isLicensePlateAllowed}
				highlightedVehicleVIN={highlightedVehicleVIN}
				VINEditDisabled={VINEditDisabled}
				apiException={apiException}
			/>
		</Modal>
	);
};

const InfoForNotification = ({ model, VIN }) => {
	const { t } = useTranslation(['common']);

	return (
		<div>
			<div className="disp-flex train train__center">
				<ISPIcon size={IconSize.SMALL} type={IconType.CHECKMARK} variant={IconVariant.BLUE} />
				&nbsp;
				<Trans i18nKey="vehicles:added-vehicle-model-notification" className="train__justify-center m-l-5">
					<span className="DaimlerCS-Bold">{{ model }}</span>
					{' saved to vehicle list.'}
				</Trans>
			</div>
			<div className="m-t-3 m-l-3">
				<Button
					className="w-40-p m-l-5"
					dataQA={DATA_QA.VEHICLE_OPEN_DETAIL_BUTTON}
					variant={ButtonVariant.FLAT}
					id={getComponentId([Pages.GLOBAL, Components.VEHICLE_LIST, Buttons.OPEN])}
					onClick={() => {
						router.push(
							{
								pathname: getRoutesAs(routes.CATALOGUE_OVERVIEW, router.query),
								query: { vin: VIN },
							},
							undefined,
							{ shallow: true }
						);
					}}
				>
					{t('common:open')}
				</Button>
			</div>
		</div>
	);
};

export default AddVehicleModal;
