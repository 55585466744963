import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import Price, { PriceThemes } from '@isp/price';

const OrderPriceSummary = ({ orderPriceInfo }) => {
	const { t } = useTranslation(['shop', 'orders']);
	const {
		totalSum,
		listPriceSum,
		netPriceSum,
		discountSum,
		specialOfferDiscountSum,
		deliveryCost,
		vat,
	} = orderPriceInfo;

	return (
		<div className="price-summary">
			<div />
			<div />
			<div>
				<div className="b-b-1 bc-very-light-pink-3 p-v-2">
					<div className="disp-flex train__space-between">
						<div className="no-white-space color-brownish-grey m-r-15">{t('shop:sum-of-list-prices')}</div>
						<Price
							amount={listPriceSum}
							theme={PriceThemes.SECONDARY}
							className="disp-flex train__center train__flex-end"
						/>
					</div>
					{!isEmpty(discountSum) && (
						<div className="disp-flex train__space-between">
							<div className="no-white-space color-brownish-grey m-r-15">{t('shop:base-discount')}</div>
							<Price
								amount={`- ${discountSum}`}
								theme={PriceThemes.SECONDARY}
								className="disp-flex train__center train__flex-end"
							/>
						</div>
					)}
					{!isEmpty(specialOfferDiscountSum) && (
						<div className="disp-flex train__space-between">
							<div className="no-white-space color-brownish-grey m-r-15">{t('shop:promotion-discount')}</div>
							<Price
								amount={`- ${specialOfferDiscountSum}`}
								theme={PriceThemes.SECONDARY}
								className="disp-flex train__center train__flex-end"
							/>
						</div>
					)}
				</div>
				<div className="b-b-1 bc-very-light-pink-3 p-v-2">
					<div className="disp-flex train__space-between">
						<div className="no-white-space color-brownish-grey m-r-15">{t('shop:total-net-price')}</div>
						<Price
							amount={netPriceSum}
							theme={PriceThemes.SECONDARY}
							className="disp-flex train__center train__flex-end"
						/>
					</div>
					{!isEmpty(deliveryCost) && (
						<div className="disp-flex train__space-between">
							<div className="no-white-space color-brownish-grey m-r-15">{t('shop:delivery-cost')}</div>
							<Price
								amount={deliveryCost}
								theme={PriceThemes.SECONDARY}
								className="disp-flex train__center train__flex-end"
							/>
						</div>
					)}
					<div className="disp-flex train__space-between">
						<div className="no-white-space color-brownish-grey m-r-15">{`${t('orders:order-details-tax')} ${
							!isNil(orderPriceInfo.vat.rate) ? `(${orderPriceInfo.vat.rate}%)` : ''
						}`}</div>
						<Price
							amount={vat.value}
							theme={PriceThemes.SECONDARY}
							className="disp-flex train__center train__flex-end"
						/>
					</div>
				</div>
				<div className="disp-flex train__space-between p-v-2">
					<div className="no-white-space color-brownish-grey m-r-15">{t('orders:order-details-total')}</div>
					<Price
						amount={totalSum}
						theme={PriceThemes.DEFAULT}
						className="disp-flex train__center train__flex-end fs-28"
					/>
				</div>
			</div>
		</div>
	);
};

export default OrderPriceSummary;
