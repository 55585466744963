import { getOrCreateStore } from '@Lib/with-redux-store';
import get from 'lodash/get';
import { TFunction } from 'react-i18next';
import { BasketValidationStatus, BASKET_VALIDATION_TRANSLATION } from '@Constants/shop';

interface BasketValidationMessageOptions {
	gemsID?: string;
	gsID?: string;
	companyIspCode?: string;
	systemNumber?: string;
	userName?: string;
	customParameterId?: string;
	ppvMaxCreditCount?: number;
	ppvAvailableCreditCount?: number;
}
const getOptions = () => {
	const store = getOrCreateStore({});

	const gemsID = get(store.getState().user, 'userId', '') || '';
	const gsID = get(store.getState().organization.organizationUsers, 'gssnId', '') || '';
	const userName = get(store.getState().user, 'userId', '') || '';
	const companyIspCode = ''; // TODO: what should be ?

	return { gemsID, gsID, companyIspCode, userName };
};

export const getBasketValidationErrorMessage = (
	translate: TFunction,
	errorCode: BasketValidationStatus = BasketValidationStatus.UNKNOWN_ERROR,
	options: BasketValidationMessageOptions = null,
	isProductPage = true
): string => {
	let translationKey = '';

	if (errorCode in BasketValidationStatus && errorCode !== BasketValidationStatus.UNKNOWN_ERROR) {
		translationKey = BASKET_VALIDATION_TRANSLATION[errorCode];
	} else {
		translationKey = isProductPage ? 'shop:something-went-wrong-try-later' : '';
	}

	options = { ...getOptions(), ...options };

	return translate(translationKey, options);
};

export const resolveErrorCodeAndParameters = error => {
	const errorCode: BasketValidationStatus = get(error, 'response.data.Data.Code');

	const customParameterId: string = get(error, 'response.data.Data.InvalidParameter', null);

	return { errorCode, customParameterId };
};
