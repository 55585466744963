import './index.scss';

const Banner = ({ content }) => {
	return (
		<div className="webparts-campaigns__banner train__justify-center disp-flex-column color-black-two">
			<div className="fs-38 DaimlerCAC-Regular">{content?.name}</div>
			<div className="fs-14 text-justify">{content?.subtitle}</div>
		</div>
	);
};

export default Banner;
