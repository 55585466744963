export const IDENTIFIER_DOCUMENT_TYPE_MAPPING = {
	MENU: { documentType: 'menu', useIdentifier: false },
	CAMPAIGNS: { documentType: 'campaigns', useIdentifier: false },
	NEWS: { documentType: 'news', useIdentifier: false },
	RATING_QUESTIONS: { documentType: 'ratingQuestions', useIdentifier: false },
	NEWSLETTER: { documentType: 'newsletter', useIdentifier: false },
	FOOTER_MENU_EXTERNAL_LINKS: { documentType: 'footerLinks', useIdentifier: false },
	NAVIGATION: { documentType: 'standardisedNav', useIdentifier: false },
	RELEASES: { documentType: 'releases', useIdentifier: false },
	PARTS: { documentType: 'productStorePage', useIdentifier: true },
	CHEMICAL_PRODUCTS_AND_CAR_CARE: { documentType: 'productStorePage', useIdentifier: true },
	ORIGINAL_ENGINE_OIL: { documentType: 'productStorePage', useIdentifier: true },
	TIRES: { documentType: 'productStorePage', useIdentifier: true },
	PRODUCT_FILTER_CONFIGURATION_PARTS: { documentType: 'productFilterConfiguration', useIdentifier: true },
	PRODUCT_FILTER_CONFIGURATION_CHEMICAL_PRODUCTS: { documentType: 'productFilterConfiguration', useIdentifier: true },
	TECHNICAL_DATA: { documentType: 'pagesTechpub', useIdentifier: false },
	FAQ_PAGE: { documentType: 'repoFaq', useIdentifier: false },
	DIGITAL_SERVICE_BOOKLET: { documentType: 'solutionPage', useIdentifier: true },
	XENTRY_DIAGNOSIS_SYSTEM: { documentType: 'solutionPage', useIdentifier: true },
	XENTRY_FLASH: { documentType: 'solutionPage', useIdentifier: true },
	RETAIL_DATA_STORAGE: { documentType: 'solutionPage', useIdentifier: true },
	XENTRY_ACCESSORIES: { documentType: 'solutionPage', useIdentifier: true },
	PTI: { documentType: 'solutionPage', useIdentifier: true },
	PTI_FILTERABLE_TABLE_ITEMS: '',
	WIS: { documentType: 'shopProduct', useIdentifier: true },
	ASRA: { documentType: 'shopProduct', useIdentifier: true },
	PARTS_INFORMATION: { documentType: 'shopProduct', useIdentifier: true },
	XRD: { documentType: 'shopProduct', useIdentifier: true },
	XENTRY_TIPS: { documentType: 'shopProduct', useIdentifier: true },
	RMS: { documentType: 'shopProduct', useIdentifier: true },
	RDS: { documentType: 'shopProduct', useIdentifier: true },
	XENTRY_TIPS_MOBILE: { documentType: 'shopProduct', useIdentifier: true },
	XENTRY_PASS_THRU_EU: { documentType: 'shopProduct', useIdentifier: true },
	XENTRY_SCOPE: { documentType: 'shopProduct', useIdentifier: true },
	B2B_CONNECT_APP: { documentType: 'shopProduct', useIdentifier: true },
	CUSTOM_NOTIFICATIONS: { documentType: 'customNotifications', useIdentifier: false },
	SITEMAP: { documentType: 'sitemapLink', useIdentifier: false },
	EQUIPMENT_PAGE: { documentType: 'equipmentPage', useIdentifier: false },
	MARKETING_CAMPAIGN_PAGES: { documentType: 'marketingCampaigns', useIdentifier: false },
	REMAN_VEHICLE_PAGE: { documentType: 'remanVehiclePage', useIdentifier: false },
	GENUINE_VEHICLE_PAGE: { documentType: 'genuinePartsVehiclePage', useIdentifier: false },
	IMPRINT: '',
};
