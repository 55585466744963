import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import { NewsItem } from '@Umbraco/news';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { MARK_READ_NEWS } from '@Reducers/sections/actions';

export const useNewsNotification = () => {
	const news = useSelector<RootState, NewsItem[]>(state => state.sections.news);
	const readNews = useSelector<RootState, string[]>(state => state.sections.readNews);

	const unreadNewsItem = news.find(newsItem => !readNews.includes(newsItem.id));

	return !!unreadNewsItem;
};

export const markAllUnreadNews = () => {
	const store = getOrCreateStore({});
	const { news, readNews } = store.getState().sections;
	store.dispatch(MARK_READ_NEWS(...news.map(newsItem => newsItem.id).filter(newsItem => !readNews.includes(newsItem))));
};
