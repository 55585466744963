import { IconType, IconVariant, IconSize } from '@isp/icon';

export enum OrderHistoryStatus {
	CONFIRMED = 'CONFIRMED',
	ORDERED = 'ORDERED',
	CANCELED = 'CANCELED',
	DONE = 'DONE',
}

export const ORDER_RATING_TEXT_AREA_HEIGHT = 44;
export const ORDER_RATING_TEXT_AREA_LIMIT = 8000;
export const RATING_ALLOWED_STATUSES = [OrderHistoryStatus.CONFIRMED, OrderHistoryStatus.ORDERED];

export const ORDER_STATUS_MAPPING = {
	[OrderHistoryStatus.CONFIRMED]: {
		iconType: IconType.STATUS_DONE,
		iconSize: IconSize.MEDIUM,
		iconVariant: IconVariant.LIME_GREEN,
		description: 'orders:confirmed',
		detail: 'orders:confirmed-label',
	},
	[OrderHistoryStatus.ORDERED]: {
		iconType: IconType.CLOCK,
		iconSize: IconSize.MEDIUM,
		iconVariant: IconVariant.BLUE,
		description: 'orders:pending',
		detail: 'orders:ordered-label',
	},
	[OrderHistoryStatus.CANCELED]: {
		iconType: IconType.ERROR2,
		iconSize: IconSize.MEDIUM,
		iconVariant: IconVariant.BLOODRED,
		description: 'orders:canceled',
		detail: null,
	},
};
