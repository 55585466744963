import { HomePartialKeys } from '@Constants/home';
import { MS_IN_SEC } from '@Constants/common';
import { useResize } from './useEvent';
import useInterval from './useInterval';

const useHeaderHeight = () => {
	const calcTop = () => {
		const headerSection = document.querySelector(`[data-id=${HomePartialKeys.HOME}]`);

		if (headerSection) {
			const headerSectionPosition = headerSection.getBoundingClientRect();
			document.documentElement.style.setProperty('--mega-menu-top-position', `${headerSectionPosition.bottom}px`);
		}
	};

	useResize(calcTop, { init: true });
	useInterval(calcTop, MS_IN_SEC);
};

export default useHeaderHeight;
