import client from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { Image, toImage } from '@Umbraco/models';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export interface LuckyDrawPageContent {
	id: string;
	image: Image;
	description: string;
	startDate: Dayjs;
	endDate: Dayjs;
	dataProtectionEmail: string;
	active: boolean;
}

const toLuckyDrawPageContent: (rawData) => LuckyDrawPageContent = rawData => {
	const startDate = dayjs(rawData.startDate);
	const endDate = dayjs(rawData.endDate);
	const currentDate = dayjs();

	return {
		id: rawData._id,
		image: toImage(rawData.media),
		description: rawData.description,
		startDate,
		endDate,
		dataProtectionEmail: rawData.dataProtectionEmail,
		active: currentDate.isBetween(startDate, endDate),
	};
};

export async function getLuckyDrawContent(market: string, lang: string) {
	const id = CONFIG.LUCKY_DRAW_CONTEST;
	const result = await client(`/content/${id}/descendants/1/1000`, market, lang);
	const luckyDraws = result?._embedded.content;
	const activeContest = luckyDraws?.map(item => toLuckyDrawPageContent(item)).find(item => item.active);
	return activeContest;
}
