import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import parse from 'html-react-parser';
import orderBy from 'lodash/orderBy';
import { client } from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { Image, toImage } from '@Umbraco/models';
import { UNSET_DATE_TIME, filterInvalidItems } from '@Umbraco/helpers';
import { transform } from '@Umbraco/helpers/transform';
import { PrivacyType } from '@Umbraco/notifications';
import { splitArrayByCondition } from '@Helpers/common/array';

interface AttachmentFile {
	_url: string;
}

export interface NewsAttachmentItem {
	attachmentFileName: string;
	attachmentFile: AttachmentFile;
	_id: string;
}
export interface NewsItem {
	id: string;
	title: string;
	media: Image;
	description: string;
	dateTime: Date | string;
	content: string;
	author?: string;
	category?: string;
	attachments: NewsAttachmentItem[];
	privacy: PrivacyType;
}

const toNewsItem = item =>
	({
		id: item._id,
		title: item?.title || '',
		media: item?.media ? toImage(item.media) : null,
		description: item.description,
		dateTime: new Date(item.dateTime),
		content: item.newsItemContent
			? renderToStaticMarkup(parse(item.newsItemContent, { replace: transform }) as ReactElement)
			: null,
		author: item?.author || '',
		category: item.category,
		attachments: item?.attachments?.filter(attachment => attachment?.attachmentFile?._url) || [],
		privacy: item?.privacy || PrivacyType.NO_PRIVACY,
	} as NewsItem);

export async function getNews(market: string, lang: string, isUserLoggedIn: boolean): Promise<NewsItem[]> {
	const id = CONFIG.NEWS;
	const newsRes = await client(`content/${id}/children/0/150`, market, lang);
	const news = filterInvalidItems(newsRes?._embedded.content) || [];
	const [newsWithValidDate, newWithInvalidDate] = splitArrayByCondition(
		news,
		newsItem => newsItem.dateTime !== UNSET_DATE_TIME
	);
	const newsByDate = [...newWithInvalidDate, ...orderBy(newsWithValidDate, 'dateTime', 'desc')];
	const filterType = isUserLoggedIn ? PrivacyType.PUBLIC : PrivacyType.PRIVATE;

	return newsByDate.map(toNewsItem).filter(item => item.privacy !== filterType);
}
