import Chip, { ChipThemes } from '@isp/chip';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import './index.scss';

const ModuleHeader = ({ title, note = null }) => (
	<div className="disp-grid home__modules__header m-b-16">
		{note && (
			<>
				<div>
					<Chip
						className="home__modules__header__chip text-center color-brownish-grey"
						theme={ChipThemes.SOLID_LIGHT}
						value={note}
					/>
				</div>
				<Icon className="h-12-px" size={IconSize.TINY} type={IconType.CHAIN} variant={IconVariant.AZURE} />
			</>
		)}
		<h2 className="home__modules__header__title text-center DaimlerCAC-Regular fs-64 fw-normal">{title}</h2>
	</div>
);

export default ModuleHeader;
