import { MetaSettingPages } from '@Constants/common';
import { FooterGroups } from '@Constants/footer';

export enum LegalTabPaths {
	PROVIDER = 'provider',
	PRIVACY_STATEMENT = 'privacy-statement',
	LEGAL_NOTICE = 'legal-notice',
	TERMS_OF_USE = 'terms-of-use',
}

export const LEGAL_TAB_TO_ID = {
	[LegalTabPaths.PROVIDER]: FooterGroups.GMBH,
	[LegalTabPaths.PRIVACY_STATEMENT]: FooterGroups.DATA_PROTECTION,
	[LegalTabPaths.LEGAL_NOTICE]: FooterGroups.LEGAL_NOTICE,
	[LegalTabPaths.TERMS_OF_USE]: FooterGroups.TERMS_OF_USAGE,
};

export const LEGAL_ID_TO_TAB = {
	[FooterGroups.GMBH]: LegalTabPaths.PROVIDER,
	[FooterGroups.DATA_PROTECTION]: LegalTabPaths.PRIVACY_STATEMENT,
	[FooterGroups.LEGAL_NOTICE]: LegalTabPaths.LEGAL_NOTICE,
	[FooterGroups.TERMS_OF_USAGE]: LegalTabPaths.TERMS_OF_USE,
};

export const LEGAL_TAB_TO_META_SETTING = {
	[LegalTabPaths.PROVIDER]: MetaSettingPages.PROVIDER,
	[LegalTabPaths.PRIVACY_STATEMENT]: MetaSettingPages.PRIVACY_STATEMENT,
	[LegalTabPaths.LEGAL_NOTICE]: MetaSettingPages.LEGAL_NOTICE,
	[LegalTabPaths.TERMS_OF_USE]: MetaSettingPages.TERMS_OF_USE,
};

export type CustomRouteType = 'asp' | 'campaign';

export enum IssueType {
	ILLEGAL_CONTENT_AND_VIOLATIONS,
	COMPLAINT,
	TRUSTWORTHY_WHISTLEBLOWER,
	GENERAL_INQUIRY,
}
