import Modal, { ModalSize } from '@isp/modal';
import { useTranslation } from '@Lib/i18n';
import { logout } from '@User-operations';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import DATA_QA from '@Constants/style/data-qa-selector.json';

const LogoutConfirmation = ({ setDisplayLogout }) => {
	const { t } = useTranslation(['login']);

	const hideLogout = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		setDisplayLogout(false);
	};

	const handleLogout = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		logout();
	};

	return (
		<Modal
			show
			footerPrompt
			size={ModalSize.SM}
			primaryButtonAction={handleLogout}
			primaryButtonText={t('login:confirm-log-out')}
			title={t('login:really-want-to-log-out')}
			secondaryButtonText={t('login:stay-logged-in')}
			secondaryButtonAction={hideLogout}
			primaryButtonId={getComponentId([Pages.MODAL, Components.LOGOUT, Buttons.CONFIRM])}
			secondaryButtonId={getComponentId([Pages.MODAL, Components.LOGOUT, Buttons.DENY])}
			dataQAPrimaryButton={DATA_QA.LOGOUT_MODAL_LOGOUT_BUTTON}
			dataQASecondaryButton={DATA_QA.LOGOUT_MODAL_STAY_LOGGED_IN_BUTTON}
		>
			<span className="fs-14 color-black-two">{t('login:please-confirm-log-out')}</span>
		</Modal>
	);
};

export default LogoutConfirmation;
