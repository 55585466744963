import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { RootState } from '@Redux';
import { TOGGLE_NOTIFICATIONS_MENU } from '@Reducers/modals/actions';
import { TOGGLE_INCIDENT_MANAGEMENT_NOTIFICATION_BANNER } from '@Reducers/notification/actions';
import IncidentManagementBannerItems from '@Shared/header/IncidentManagementBanner/IncidentManagementBannerItems';
import { scoreAvailability } from '@Helpers/notifications';
import { StorageKeys } from '@Helpers/storage';
import { NotificationsTypes } from '@Constants/notifications';
import '@Shared/header/IncidentManagementBanner/index.scss';

const IncidentManagementBanner = () => {
	const dispatch = useDispatch();
	const [bannerOpen, setBannerOpen] = useState(false);
	const incidentManagementData = useSelector<RootState>(state =>
		get(state.notification, 'incidentManagementNotificationData', [])
	);
	const leitstandNotificationsData = useSelector<RootState>(state =>
		get(state.notification, 'leitstandNotificationsData', [])
	);

	const bannerData = ((leitstandNotificationsData as any[]) || [])
		.map(item => ({
			...item,
			leitstandType: item.type,
			type: NotificationsTypes.LEITSTAND,
		}))
		.concat((incidentManagementData as any[]).map(item => ({ ...item, type: NotificationsTypes.INCIDENT })))
		.sort((first, second) => {
			return scoreAvailability(second, first) - scoreAvailability(first, second);
		});
	const showBanner = !isEmpty(bannerData);

	const getBannerClassName = () =>
		classNames('incident-management-banner', {
			'incident-management-banner--close': !bannerOpen,
		});

	const onReadMoreClick = () => dispatch(TOGGLE_NOTIFICATIONS_MENU());

	const onBannerCloseClick = () => {
		dispatch(TOGGLE_INCIDENT_MANAGEMENT_NOTIFICATION_BANNER(false));
		setBannerOpen(false);
	};

	useEffect(() => {
		const isBannerClosedBefore = sessionStorage.getItem(StorageKeys.STORAGE_KEY_INCIDENT_MANAGEMENT_BANNER) === 'false';
		setBannerOpen(showBanner && !isBannerClosedBefore);
	}, [incidentManagementData, leitstandNotificationsData]);

	return (
		<div className={getBannerClassName()}>
			<div className="incident-management-banner__wrapper train__center color-black-two b-b-1 bc-asphalt">
				<IncidentManagementBannerItems bannerData={bannerData} onReadMore={onReadMoreClick} />
				<Icon
					clickable
					type={IconType.CLOSE}
					size={IconSize.SMALLER}
					variant={IconVariant.BLACK}
					onClick={onBannerCloseClick}
					className="incident-management-banner__close"
				/>
			</div>
		</div>
	);
};

export default IncidentManagementBanner;
