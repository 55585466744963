import { useTranslation } from 'react-i18next';
import BannerCard from '@Shared/header/IncidentManagementBanner/items/BannerCard';
import {
	getIncidentManagementNotificationType,
	getNotificatonMessageWithAffectedProduct,
	isNotificationVisibleInCurrentMarket,
} from '@Helpers/notifications';
import {
	INCIDENT_NOTIFICATION_TYPE_ICON_MAP,
	INCIDENT_NOTIFICATION_ICON_TYPE_TITLE_MAP,
} from '@Constants/notifications';

const IncidentBanner = ({ notificationItem, onReadMore }) => {
	const { t } = useTranslation(['common']);
	const message = getNotificatonMessageWithAffectedProduct(notificationItem);
	const notificationType = getIncidentManagementNotificationType(notificationItem);
	const isVisibleInMarket = isNotificationVisibleInCurrentMarket(notificationItem);

	const { iconType, iconVariant } = INCIDENT_NOTIFICATION_TYPE_ICON_MAP[notificationType];

	return (
		isVisibleInMarket && (
			<BannerCard
				iconType={iconType}
				iconVariant={iconVariant}
				title={t(INCIDENT_NOTIFICATION_ICON_TYPE_TITLE_MAP[notificationType])}
				description={message}
				onReadMore={onReadMore}
			/>
		)
	);
};

export default IncidentBanner;
