import { useTranslation } from 'react-i18next';
import Price, { PriceThemes } from '@isp/price';
import { formatDate } from '@Helpers/common/date';
import { StockTypeTranslation } from '@Constants/account';

const OrderInfo = ({ orderDetails }) => {
	const { retailerInfo, customerInfo, orderComment, vin } = orderDetails;
	return (
		<div className="order-info">
			<Seller sellerInfo={retailerInfo} />
			<Purchaser purchaserInfo={customerInfo} vin={vin} />
			{orderComment && (
				<div className="order-info__comment">
					<OrderComment comment={orderComment} />
				</div>
			)}
			<OrderSummary orderDetails={orderDetails} />
		</div>
	);
};

const Seller = ({ sellerInfo }) => {
	const { t } = useTranslation(['orders']);
	const { companyName, address } = sellerInfo;
	const { description, zipCode, city, country } = address;

	return (
		<div>
			<div className="fs-16 m-b-2">{t('orders:ordered-from-title')}</div>
			<div className="color-brownish-grey">{companyName}</div>
			<div className="color-brownish-grey">{description}</div>
			<div className="color-brownish-grey">
				{zipCode} {city}
			</div>
			<div className="color-brownish-grey">{country}</div>
		</div>
	);
};

const Purchaser = ({ purchaserInfo, vin }) => {
	const { t } = useTranslation(['orders', 'address']);
	const { customerId, address } = purchaserInfo;
	const { companyName, description, zipCode, city, country } = address;

	return (
		<div>
			<div className="fs-16 m-b-2">{t('orders:ordered-for-title')}</div>
			<div className="color-brownish-grey">
				{t('address:customer-id')} {customerId}
			</div>
			<div className="color-brownish-grey">{companyName}</div>
			<div className="color-brownish-grey">{description}</div>
			<div className="color-brownish-grey">
				{zipCode} {city}
			</div>
			<div className="color-brownish-grey">{country}</div>
			{vin && <div className="color-brownish-grey">{`${t('orders:vin')}: ${vin}`}</div>}
		</div>
	);
};

const OrderComment = ({ comment }) => {
	const { t } = useTranslation(['common']);
	return (
		<div>
			<div>{t('common:comment')}</div>
			<div>{comment}</div>
		</div>
	);
};

const OrderSummary = ({ orderDetails }) => {
	const { t } = useTranslation(['common', 'orders']);
	const { orderDate, orderedBy, jobNumber, price, orderType } = orderDetails;
	const { totalSum } = price;

	return (
		<div className="train train__flex-end">
			<div className="order-info__summary">
				<div>{t('common:date')}</div>
				<div>{t('orders:ordered-by')}</div>
				{jobNumber && <div>{t('orders:job-number')}</div>}
				{orderType && <div>{t('orders:order-type')}</div>}
				<div>{t('orders:order-details-total')}</div>
			</div>
			<div className="order-info__summary">
				<div className="color-brownish-grey disp-flex train__center train__flex-end">{formatDate(orderDate)}</div>
				<div className="color-brownish-grey disp-flex train__center train__flex-end">{orderedBy}</div>
				{jobNumber && <div className="color-brownish-grey disp-flex train__center train__flex-end">{jobNumber}</div>}
				{orderType && (
					<div className="color-brownish-grey disp-flex train__center train__flex-end text-right">
						{t(StockTypeTranslation[orderType])}
					</div>
				)}
				<Price amount={totalSum} theme={PriceThemes.TERTIARY} className="disp-flex train__center train__flex-end" />
			</div>
		</div>
	);
};

export default OrderInfo;
