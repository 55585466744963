import { ReactElement } from 'react';
import Icon, { IconType, IconVariant } from '@isp/icon';
import LinkWithIcon, { LinkVariant } from '@isp/link-with-icon';

const FootnoteItem = ({
	id = '',
	className = '',
	onClick,
	iconType = null,
	secondaryIconType = null,
	content,
	colorOption = 'default',
}: {
	id?: string;
	className?: string;
	iconType: IconType;
	secondaryIconType?: IconType;
	content: string | string[] | ReactElement | ReactElement[];
	onClick?: (e) => void;
	colorOption?: 'default' | 'white';
}) => {
	const iconVariant = colorOption === 'white' ? IconVariant.WHITE : IconVariant.BLACK_TWO;

	return (
		<span className={`${className} disp-flex`}>
			<LinkWithIcon
				iconType={iconType || secondaryIconType}
				iconVariant={iconVariant}
				underline
				variant={colorOption === 'white' ? LinkVariant.PRIMARY : LinkVariant.SECONDARY}
				disableHref
				onClick={onClick}
				id={id}
			>
				{iconType && secondaryIconType && <Icon type={secondaryIconType} variant={iconVariant} />}
				<span>{content}</span>
			</LinkWithIcon>
		</span>
	);
};

export default FootnoteItem;
