import { useEffect, useRef } from 'react';
import Icon, { IconType, IconVariant } from '@isp/icon';
import ModuleHeader from '../Shared/ModuleHeader';
import './index.scss';

const GlanceModule = ({ data }) => {
	const boxWrapper = useRef<HTMLDivElement>();

	const { title, note, boxes } = data;

	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			if (boxWrapper.current && !boxWrapper?.current?.classList?.contains('animation')) {
				boxWrapper?.current?.classList.toggle('animation', entries[0].isIntersecting);
			}
		});

		observer.observe(boxWrapper?.current);
	}, []);

	return (
		<div className="home__modules p-h-10 m-auto disp-grid grid--item-gap-3 train__justify-center">
			<ModuleHeader title={title} note={note} />
			<div ref={boxWrapper} className="home__modules__glance disp-flex grid--item-gap-2">
				{boxes?.map((box, index) => (
					<div key={`glance-items-${index}`} className="bg-white-smoke disp-grid 2 home__modules__glance__box">
						<div className="h-64-px">
							<Icon className="h-44-px w-44-px" type={IconType[box.icon]} variant={IconVariant.BLACK_TWO} />
						</div>
						<div className="DaimlerCS-Bold color-black-two fs-16 h-40-px">{box.title}</div>
						<div className="fs-16 color-brownish-grey">{box.description}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default GlanceModule;
