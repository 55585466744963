import get from 'lodash/get';
import serviceEndpoints from '@Service-endpoints';
import getAppEnvironment from './getAppEnvironment';

export const getCanonicalLink = req => {
	const config = {
		APP_ENV: getAppEnvironment(),
	};
	const env = config.APP_ENV;
	const baseURL = get((serviceEndpoints as any).config[env], 'baseUrl');
	return req ? get(`${baseURL}${req.originalUrl}`.split('?'), '[0]') : null;
};
