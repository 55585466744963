import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from '@Lib/i18n';
import { RootState } from '@Redux';
import routes, { getRoutesAs } from '@Routes';
import { IconType } from '@isp/icon';
import Button, { ButtonVariant } from '@isp/button';
import DATA_QA from '@Constants/style/data-qa-selector.json';

const BasketButton = () => {
	const router = useRouter();
	const { t } = useTranslation(['common']);
	const { market } = router.query;
	const basketCount = useSelector<RootState, number>(state => state.shop.basketCount);
	const itemLabel = basketCount > 1 ? 'shop:items-plural' : 'shop:item-singular';

	const onBasketClick = () => {
		router.push(routes.BASKET, getRoutesAs(routes.BASKET, { market }));
	};
	return (
		<Button
			variant={ButtonVariant.QUATERNARY}
			className={classNames('p-h-2 sticky-toolbar__basket h-40-px br-5', {
				'bg-brown-grey': basketCount === 0,
				'bg-black-two:': basketCount !== 0,
			})}
			startIconType={IconType.BASKET}
			onClick={onBasketClick}
			dataQA={DATA_QA.GO_TO_BASKET_BUTTON}
		>
			<span className="sticky-toolbar__basket__label">{t('common:basket')}</span>
			{basketCount > 0 && <span className="m-r-2">{t(itemLabel, { count: basketCount })}</span>}
		</Button>
	);
};

export default BasketButton;
