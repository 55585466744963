import isEmpty from 'lodash/isEmpty';
import { actionTypes } from '@Reducers/notification/actionTypes';
import { filterCMSNotificationsByDate } from '@Helpers/notifications';
import { StorageKeys } from '@Helpers/storage';
import { getNotifications } from '@Apis/customer';
import { isLoggedIn } from '@User-operations';
import {
	IncidentManagamentNotificationItem,
	getCustomNotifications,
	getIncidentManagementNotifications,
} from '@Umbraco/notifications';

export const TOGGLE_INCIDENT_MANAGEMENT_NOTIFICATION_BANNER = newState => (dispatch, getState) => {
	const toggle = newState && !getState().notification.showIncidentManagementBanner;
	sessionStorage.setItem(StorageKeys.STORAGE_KEY_INCIDENT_MANAGEMENT_BANNER, JSON.stringify(toggle));
	dispatch({
		type: actionTypes.TOGGLE_INCIDENT_MANAGEMENT_NOTIFICATION_BANNER,
		showIncidentManagementBanner: toggle,
	});
};

export const SET_INCIDENT_MANAGEMENT_NOTIFICATION = (data = []) => dispatch => {
	const notifications = (filterCMSNotificationsByDate(data) as IncidentManagamentNotificationItem[]) || [];
	dispatch({
		type: actionTypes.SET_INCIDENT_MANAGEMENT_NOTIFICATION,
		incidentManagementNotificationData: notifications,
		showIncidentManagementBanner: !isEmpty(notifications),
	});
};

export const SET_LEITSTAND_NOTIFICATION = (data = []) => dispatch => {
	dispatch({
		type: actionTypes.SET_LEITSTAND_NOTIFICATION,
		leitstandNotificationsData: data || [],
	});
};

export const SET_LICENSE_NOTIFICATION = (data = []) => dispatch => {
	dispatch({
		type: actionTypes.SET_LICENSE_NOTIFICATION,
		expiringLicenses: data || [],
	});
};

export const SET_CUSTOM_NOTIFICATIONS = (data = []) => dispatch => {
	dispatch({
		type: actionTypes.SET_CUSTOM_NOTIFICATIONS,
		customNotifications: data || [],
	});
};

export const GET_NOTIFICATIONS = (market, language) => dispatch => {
	if (isLoggedIn()) {
		// Leitstand & licenses
		getNotifications(language).then(response => {
			dispatch(SET_LEITSTAND_NOTIFICATION(response.controlUnitNotifications || []));
			dispatch(SET_LICENSE_NOTIFICATION(response.expiringLicenses || []));
		});

		// CMS Incident notifications
		getIncidentManagementNotifications(market, language).then(response => {
			dispatch(SET_INCIDENT_MANAGEMENT_NOTIFICATION(response || []));
		});

		// CMS Custom notifications
		getCustomNotifications(market, language).then(response => {
			dispatch(SET_CUSTOM_NOTIFICATIONS(response || []));
		});
	}
};
