import { useSelector } from 'react-redux';
import { Retailer } from '@Reducers/organization/models';
import { RootState } from '@Redux';

export const usePreferredAddress = () => {
	const retailers = useSelector<RootState, Retailer[]>(state => state.organization.dealers);
	const selectedRetailer = retailers?.find(r => r.isPreferred);
	const addresses = selectedRetailer?.deliveryAddresses;
	const preferredAddress = addresses?.find(address => address.isPreferred);
	return { gssnId: selectedRetailer?.id, ...preferredAddress };
};

export const usePreferredRetailer = () => {
	const retailers = useSelector<RootState, Retailer[]>(state => state.organization.dealers);
	const selectedRetailer = retailers?.find(r => r.isPreferred);
	return selectedRetailer;
};
