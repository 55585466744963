import { forwardRef, useImperativeHandle, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { IconType } from '@isp/icon';
import Loading, { LoadingSizes } from '@isp/loading';
import { AppDispatch } from '@Redux';
import CodeValidityButton from '@Shared/webparts/CodeValidityButton';
import ISPError from '@Shared/error';
import FootnoteItem from '@Shared/webparts/PartItemFootnotes/FootnoteItem';
import PartItemPackage from '@Shared/webparts/PartItemFootnotes/PartItemPackage';
import ReplacementChainFootnote from '@Shared/webparts/PartItemFootnotes/ReplacementChainFootnote';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import {
	Part,
	ExplosionDrawingPart,
	RelatedPartPackage,
	PartSearchRelatedPackages,
	PartColorCodeInfo,
	PartColorOptionsInfo,
	AlternativePartTypes,
} from '@Reducers/vehicle/models';
import { CatalogueReference } from '@Reducers/shop/models';
import {
	TOGGLE_FOOTNOTE_MODAL,
	TOGGLE_PART_PACKAGE_MODAL,
	TOGGLE_COLOR_SELECTION_MODAL,
	TOGGLE_COLOR_CODE_INFO_MODAL,
	TOGGLE_ALTERNATE_PART_MODAL,
} from '@Reducers/modals/actions';
import withISPUser from '@Lib/withISPUser';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import {
	ALTERNATE_REMAN_PART_AVAILABLE_MARKETS,
	ALTERNATE_STAR_PART_AVAILABLE_MARKETS,
	PartShoppingInfo,
} from '@Constants/webparts';

interface PartItemFootnotesProps {
	packageLoading: boolean;
	partPackageError: boolean;
	partPackages: RelatedPartPackage[];
	part: ExplosionDrawingPart;
	isLegacyColor: boolean;
	shoppingDetails: PartShoppingInfo;
	selectedModelId: string;
	vin: string;
	webpartsError: boolean;
	getCatalogReference: () => Promise<CatalogueReference>;
	setPartDetails: () => Promise<ExplosionDrawingPart>;
}

interface PartItemFootnotesActions {
	togglePartsPackage: () => void;
	toggleFootnote: () => void;
	toggleColorSelection: () => void;
	toggleCodeValidity: () => void;
}

const PartItemFootnotes = forwardRef<PartItemFootnotesActions, PartItemFootnotesProps>(
	(
		{
			packageLoading,
			partPackages,
			partPackageError,
			part,
			isLegacyColor,
			shoppingDetails,
			selectedModelId = '',
			vin,
			webpartsError,
			getCatalogReference,
			setPartDetails,
		},
		ref
	) => {
		const { t } = useTranslation(['vehicles', 'shop']);
		const dispatch: AppDispatch = useDispatch();
		const [errorMessage, setErrorMessage] = useState<string>(null);
		const [partDetailsLoading, setPartDetailsLoading] = useState<boolean>(false);
		const {
			partNumber,
			codeValidity,
			partDetails,
			footNotes,
			colorInfoAvailable,
			plantInformationAvailable,
			alternativePartsTypes,
			replacementChain,
		} = part;
		const packageAvailable = !isEmpty(partPackages) && !partPackageError && !packageLoading;

		const handlePartsPackage = () => {
			const selectedPartPackageInfo: PartSearchRelatedPackages = {
				packages: partPackages,
				selectedSubGroupId: partPackages[0]?.id,
				vin,
			};
			dispatch(TOGGLE_PART_PACKAGE_MODAL(selectedPartPackageInfo));
		};

		const handleFootnote = async () => {
			let updatedPart = part;

			setErrorMessage(null);

			setPartDetailsLoading(true);
			if (isEmpty(partDetails)) updatedPart = await setPartDetails();
			setPartDetailsLoading(false);

			if ((plantInformationAvailable && !isEmpty(updatedPart.partDetails?.plantInformation)) || !isEmpty(footNotes)) {
				const selectedFootnoteItem: Part = {
					partNumber: updatedPart.partNumber,
					title: updatedPart.title,
					footNotes: updatedPart.footNotes,
					plantInformation: updatedPart.partDetails?.plantInformation,
					description: '',
					shoppingDetails,
					quantity: updatedPart.selectedQuantity,
					es1Codes: updatedPart.es1Codes || [],
					fin: vin,
					...(getCatalogReference && { catalogRef: await getCatalogReference() }),
				};

				dispatch(TOGGLE_FOOTNOTE_MODAL(selectedFootnoteItem));
			} else {
				setErrorMessage('shop:something-went-wrong-try-later');
			}
		};

		const handleColorSelection = async (e = null) => {
			let updatedPart = part;

			setErrorMessage(null);

			setPartDetailsLoading(true);
			if (isEmpty(partDetails)) updatedPart = await setPartDetails();
			setPartDetailsLoading(false);

			if (e) PushDataToTagManagerForButtons(e.target.id, window.location.href);

			if (!isEmpty(updatedPart.partDetails?.colors) || isLegacyColor) {
				const selectedPartColorInfo: PartColorOptionsInfo = {
					vin,
					isLegacyColor,
					partNumber: updatedPart.partNumber,
					quantity: updatedPart.quantity,
					title: updatedPart.title,
					shoppingDetails,
					footNotes: updatedPart.footNotes,
					colorOptions: updatedPart.partDetails?.colors || [],
					webpartsError,
					...(getCatalogReference && { catalogRef: await getCatalogReference() }),
				};

				dispatch(TOGGLE_COLOR_SELECTION_MODAL(selectedPartColorInfo));
			} else {
				setErrorMessage('shop:something-went-wrong-try-later');
			}
		};

		const handleAlternativePart = async e => {
			let updatedPart = part;

			setErrorMessage(null);

			setPartDetailsLoading(true);
			if (isEmpty(partDetails)) updatedPart = await setPartDetails();
			setPartDetailsLoading(false);

			PushDataToTagManagerForButtons(e.target.id, window.location.href);
			if (!isEmpty(updatedPart.partDetails?.alternativeParts)) {
				const selectedPartAlternativesInfo = {
					title: updatedPart.title,
					description: updatedPart.description,
					partNumber: updatedPart.partNumber,
					quantity: updatedPart.quantity,
					shoppingDetails,
					alternativeParts: updatedPart.partDetails?.alternativeParts,
					vin,
					...(getCatalogReference && { catalogRef: await getCatalogReference() }),
				};

				dispatch(TOGGLE_ALTERNATE_PART_MODAL(selectedPartAlternativesInfo));
			} else {
				setErrorMessage('shop:something-went-wrong-try-later');
			}
		};

		const handleCodeValidity = async (e = null) => {
			let updatedPart = part;

			setErrorMessage(null);

			setPartDetailsLoading(true);
			if (isEmpty(partDetails)) updatedPart = await setPartDetails();
			setPartDetailsLoading(false);

			if (e) PushDataToTagManagerForButtons(e.target.id, window.location.href);

			const selectedPartColorCodeInfo: PartColorCodeInfo = {
				partNumber: updatedPart.partNumber,
				title: updatedPart.title,
				codeValidity: updatedPart.codeValidity,
				codeValidityMatrix: updatedPart.partDetails?.codeValidityMatrix,
				codeValidityDetails: updatedPart.partDetails?.codeValidityDetails,
			};

			dispatch(TOGGLE_COLOR_CODE_INFO_MODAL(selectedPartColorCodeInfo));
		};

		useImperativeHandle(ref, () => ({
			togglePartsPackage: handlePartsPackage,
			toggleFootnote: handleFootnote,
			toggleColorSelection: handleColorSelection,
			toggleCodeValidity: handleCodeValidity,
		}));

		return partDetailsLoading ? (
			<Loading size={LoadingSizes.MICRO} />
		) : (
			<>
				{replacementChain && <ReplacementChainFootnote selectedPart={part} vin={vin} />}
				<PartOptionsFootnoteItem
					alternativePartsTypes={alternativePartsTypes}
					partNumber={partNumber}
					handleAlternativePart={handleAlternativePart}
				/>
				{(colorInfoAvailable || isLegacyColor) && (
					<FootnoteItem
						onClick={handleColorSelection}
						content={t('shop:color-selection')}
						iconType={IconType.PAINT}
						id={getComponentId(
							[Pages.VEHICLE, Components.PARTS, Components.COLOR_SELECTION, Buttons.OPEN, partNumber],
							true,
							true
						)}
					/>
				)}
				{!selectedModelId && (
					<PartItemPackage
						packageLoading={packageLoading}
						packageAvailable={packageAvailable}
						partPackageError={partPackageError}
						handlePartsPackage={handlePartsPackage}
						partNumber={partNumber}
					/>
				)}
				{(!isEmpty(footNotes) || plantInformationAvailable) && (
					<FootnoteItem
						onClick={handleFootnote}
						content={t('vehicles:footnote')}
						iconType={IconType.NOTE}
						id={getComponentId(
							[Pages.VEHICLE, Components.PARTS, Components.FOOTNOTE, Buttons.OPEN, partNumber],
							true,
							true
						)}
					/>
				)}
				{codeValidity && (
					<div className="w-100-p overflow-hidden">
						<CodeValidityButton
							codes={codeValidity}
							onClick={handleCodeValidity}
							id={getComponentId(
								[Pages.VEHICLE, Components.CODE_VALIDITY, Buttons.OPEN, 'part-list', partNumber],
								true,
								true
							)}
						/>
					</div>
				)}
				{errorMessage && <ISPError className="fs-14 no-white-space" errorMessage={errorMessage} />}
			</>
		);
	}
);

const PartOptionsFootnote = ({ alternativePartsTypes, partNumber, handleAlternativePart }) => {
	const router = useRouter();
	const { t } = useTranslation(['vehicles', 'shop']);

	const { market } = router.query;

	const isMarketHasAlternateRemanPart = ALTERNATE_REMAN_PART_AVAILABLE_MARKETS.includes(market as string);
	const isMarketHasAlternateStarPart = ALTERNATE_STAR_PART_AVAILABLE_MARKETS.includes(market as string);
	const isMarketHasAlternatePart = isMarketHasAlternateRemanPart || isMarketHasAlternateStarPart;

	return (
		!isEmpty(alternativePartsTypes) &&
		isMarketHasAlternatePart && (
			<FootnoteItem
				onClick={handleAlternativePart}
				content={t('vehicles:part-options')}
				iconType={
					isMarketHasAlternateRemanPart &&
					alternativePartsTypes.includes(AlternativePartTypes.REMAN_PART) &&
					IconType.REMAN_PARTS_FOOTNOTE
				}
				secondaryIconType={
					isMarketHasAlternateStarPart &&
					alternativePartsTypes.includes(AlternativePartTypes.STAR_PART) &&
					IconType.START_PARTS_FOOTNOTE
				}
				id={getComponentId(
					[Pages.VEHICLE, Components.PARTS, Components.FOOTNOTE, Buttons.OPEN, 'alternate-parts', partNumber],
					true,
					true
				)}
			/>
		)
	);
};

const PartOptionsFootnoteItem = withISPUser(PartOptionsFootnote);

export default PartItemFootnotes;
