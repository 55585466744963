import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import ISPTextButton, { TextColors, HoverTypes } from '@isp/text-button';

interface PartFasterAvailableButtonProps {
	textColor?: TextColors;
	atStart?: boolean;
	className?: string;
	innerClassName?: string;
	onClick(e): void;
	id: string;
	dataQA?: string;
}

const PartFasterAvailableButton: VFC<PartFasterAvailableButtonProps> = ({
	textColor = TextColors.BLACK,
	className = '',
	innerClassName = '',
	atStart,
	onClick,
	dataQA,
	id,
}) => {
	const { t } = useTranslation(['shop']);

	return (
		<div className={`${atStart ? 'disp-flex' : 'train__flex-end'} ${className} m-t-1`}>
			<ISPTextButton
				color={textColor}
				hoverType={HoverTypes.AZURE}
				underline
				onClick={onClick}
				className={`fs-16 ${innerClassName}`}
				id={id}
				dataQA={dataQA}
			>
				{t('shop:faster-available')}
			</ISPTextButton>
		</div>
	);
};

export default PartFasterAvailableButton;
