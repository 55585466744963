import get from 'lodash/get';
import { Retailer } from '@Reducers/organization/models';
import { getRetailersStates } from './GetRetailersStates';

export const getPreferredRetailersAvailability = (retailers: Retailer[]) => {
	const { preferredRetailer } = getRetailersStates(retailers);
	return get(preferredRetailer, 'hasAvailability', false);
};

export const getRetailersAvailability = (retailers: Retailer[], retailerId: string) => {
	const { numberOfApprovedRetailers } = getRetailersStates(retailers);
	if (numberOfApprovedRetailers > 0) {
		const retailer = retailers.find(retailer => retailer.id === retailerId);
		return get(retailer, 'hasAvailability', false);
	}
	return false;
};
