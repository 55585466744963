import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconType } from '@isp/icon';
import { AppDispatch } from '@Redux';
import { SET_SHOW_PART_XWIS_DOCUMENT_MODAL } from '@Reducers/modals/actions';
import FootnoteItem from './FootnoteItem';

export default function XWISDocumentFootnote({ partNumber, vin, id = '' }) {
	const { t } = useTranslation(['shop']);
	const dispatch: AppDispatch = useDispatch();
	return (
		<FootnoteItem
			iconType={IconType.WIS_DOCUMENT}
			content={t('shop:wis-document')}
			id={id}
			onClick={() => dispatch(SET_SHOW_PART_XWIS_DOCUMENT_MODAL({ partNumber: partNumber.replace(/\s+/g, ''), vin }))}
		/>
	);
}
