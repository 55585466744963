import Router from 'next/router';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { TOGGLE_SHOW_TERMS_OF_USE_MODAL } from '@Reducers/user/actions';
import { RemoveFromQueryString, URLContainsQueryValue } from './QueryString';
import { TransformMarketWithPathname } from './TransformMarketWithPathname';

export function UrlWithoutHash(hashValue: string) {
	const { hash } = window.location;
	if (hash.includes(hashValue)) {
		return `${TransformMarketWithPathname()}`;
	}
	return `${TransformMarketWithPathname()}${hash}`;
}

export function TriggerLoginEvent(newUrl) {
	const store = getOrCreateStore({});
	const { dispatch } = store;
	if (URLContainsQueryValue('login', 'success')) {
		dispatch(TOGGLE_SHOW_TERMS_OF_USE_MODAL());
		newUrl = RemoveFromQueryString(newUrl, 'login');
		window.addEventListener('load', () => {
			Router.push(Router.pathname, newUrl, { shallow: true });
		});
	}
}
