import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import get from 'lodash/get';
import filter from 'lodash/filter';
import parse from 'html-react-parser';
import { client } from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { Image, toImage } from '@Umbraco/models';
import { transform } from '@Umbraco/helpers/transform';
import getHierarchicalDescendants from '@Umbraco/helpers';

export interface Feature {
	id: string;
	title: string;
	description: string;
	content: string;
	image: Image;
}

export interface Release {
	id: string;
	version: string;
	description: string;
	features: Feature[];
}

export const toFeature = item => ({
	id: item._id,
	title: item.title,
	description: item.description,
	content: renderToStaticMarkup(parse(item.content, { replace: transform }) as ReactElement),
	image: get(item, 'image') ? toImage(item.image) : null,
});

export const toRelease = item => ({
	id: item._id,
	version: item.version,
	features: item.features.map(toFeature),
});

export const toReleases = item => ({
	id: item._id,
	releases: get(item, 'releases', []).map(toRelease),
});

const id = CONFIG.RELEASES;

export function getReleasesDescendants(market: string, lang: string) {
	return client(`/content/${id}/descendants/1/1000`, market, lang)
		.then(async response => {
			const descendants = get(response, '_embedded.content');
			const hierarchical = getHierarchicalDescendants(id, descendants);
			return hierarchical;
		})
		.catch(err => {
			throw err;
		});
}

export function getReleases(market: string, lang: string) {
	return client(`/content/${id}`, market, lang)
		.then(async response => {
			const descendants = await getReleasesDescendants(market, lang);
			const releases = filter(descendants.children, { contentTypeAlias: 'releasesRelease' });
			return toReleases({ ...response, releases });
		})
		.catch(err => {
			throw err;
		});
}
