import NextI18Next from '@Lib/i18n';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { useDidMountEffect } from './useDidMountEffect';

const useLanguageChange = callback => {
	const language = geti18nLanguage(NextI18Next.i18n.language);

	useDidMountEffect(() => {
		callback();
	}, [language]);
};

export default useLanguageChange;
