import { useDispatch } from 'react-redux';
import Icon, { IconType, IconVariant } from '@isp/icon';
import AdvancedDropdown, { DropdownOption, DropdownOptionsHeader } from '@isp/advanced-dropdown/AdvancedDropdown';
import { useTranslation } from '@Lib/i18n';
import { UPDATE_DELIVERY_ADDRESS, UPDATE_PREFERRED_DEALER } from '@Reducers/organization/actions';
import { DealerStatuses, MIN_NUMBER_OF_RETAILER_SHOWN_DROPDOWN } from '@Constants/retailer';

import RetailerAssignedDivisionInfo from './RetailerAssignedDivisonInfo';

const RetailerDropdown = ({ dealers, dealersError }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['common', 'retailers']);
	const approvedRetailers = dealers && dealers.filter(d => d.status === DealerStatuses.APPROVED);
	const preferredRetailer = approvedRetailers && approvedRetailers.find(retailer => retailer.isPreferred);
	const approvedRetailerCount = approvedRetailers?.length || 0;
	const nonSelectedRetailers =
		approvedRetailers &&
		(approvedRetailerCount === 1
			? approvedRetailers
			: approvedRetailers.filter(retailer => retailer.id !== preferredRetailer?.id));
	const options = nonSelectedRetailers?.map(dealer => ({
		id: dealer.gssnId,
		label: dealer.name,
		subLabel: `${dealer.address?.description},${dealer.address?.zipCode},${dealer.address?.city}`,
		items: dealer.deliveryAddresses.map(address => ({
			id: address.customerNumber,
			label: address.customerNumber,
			subLabel: address.customerName,
			footnoteChild: `${address.description},${address.zipCode},${address.city}`,
		})),
		footnoteChild: <RetailerAssignedDivisionInfo dealer={dealer} />,
	}));
	const selection = {
		id: preferredRetailer?.gssnId,
		label: preferredRetailer?.name,
		// eslint-disable-next-line max-len
		subLabel: `${preferredRetailer?.address?.description},${preferredRetailer?.address?.zipCode},${preferredRetailer?.address?.city}`,
		items: preferredRetailer?.deliveryAddresses.map(address => ({
			id: preferredRetailer?.gssnId,
			label: address.customerNumber,
			subLabel: address.customerName,
			footnoteChild: <div className="p-h-2">{`${address.description},${address.zipCode},${address.city}`}</div>,
		})),
		footnoteChild: <RetailerAssignedDivisionInfo dealer={preferredRetailer} />,
	};
	const noPreferredRetailer = {
		id: '-1',
		label: t('retailers:select-retailer'),
		subLabel: '',
	};
	const value = preferredRetailer ? selection : noPreferredRetailer;
	const optionsHeader: DropdownOptionsHeader = {
		option: selection,
		title: t('common:selected'),
	};

	const getOptionsTitle = () => {
		if (approvedRetailerCount === 1) return t('retailers:your-retailer');
		if (approvedRetailerCount === MIN_NUMBER_OF_RETAILER_SHOWN_DROPDOWN) return t('retailers:other-retailers');
		return t('retailers:choose-retailer');
	};

	const handleRetailerSelection = async (selection: DropdownOption) => {
		if (selection.items) {
			if (preferredRetailer?.gssnId !== selection.id) {
				await dispatch(UPDATE_PREFERRED_DEALER(selection.id, selection.items[0].id));
			}
		} else {
			const preferredDeliveryAddressId = preferredRetailer.deliveryAddresses.find(address => address.isPreferred)
				.customerNumber;
			if (preferredDeliveryAddressId !== selection.id) {
				dispatch(UPDATE_DELIVERY_ADDRESS(selection.id, selection.label));
			}
		}
	};

	return (
		<>
			{approvedRetailerCount > 0 && (
				<div className="retailer-dropdown">
					<AdvancedDropdown
						options={options}
						value={value}
						optionsTitle={getOptionsTitle()}
						secondLevelBackLabel={t('common:back')}
						optionsHeader={preferredRetailer && approvedRetailerCount > 1 && optionsHeader}
						onSelect={handleRetailerSelection}
					/>
				</div>
			)}
			{dealersError && <RetailerError />}
		</>
	);
};

const RetailerError = () => {
	const { t } = useTranslation(['retailers']);
	return (
		<p className="disp-flex train__center">
			<Icon type={IconType.ERROR2} variant={IconVariant.BLOODRED} />
			<span>
				{t('retailers:retailers-load-error')}
				{t('retailers:retailers-load-error-try-again')}
			</span>
		</p>
	);
};

export default RetailerDropdown;
