import { useEffect } from 'react';

const useMozillaScroll = () => {
	useEffect(() => {
		if (navigator.userAgent.indexOf('Firefox') !== -1) {
			document.querySelector('html').style.scrollBehavior = 'auto';
		}
	}, []);
};

export default useMozillaScroll;
