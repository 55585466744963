import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { IconType } from '@isp/icon';
import { TOGGLE_REPLACEMENT_CHAIN_MODAL } from '@Reducers/modals/actions';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { useTranslation } from '@Lib/i18n';
import { ReplacementChainModalProps } from '@Shared/webparts/Catalogue/CatalogueModals/ReplacementChainModal';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import FootnoteItem from './FootnoteItem';

interface ReplacementChainFootnoteProps extends ReplacementChainModalProps {
	colorOption?: 'default' | 'white';
}

export default function ReplacementChainFootnote(props: ReplacementChainFootnoteProps) {
	const { t } = useTranslation(['vehicles']);
	const dispatch = useDispatch();

	const { selectedPart, vin, colorOption } = props;
	const { partNumber, partInfo, replacementChain: replacementChainFromPart } = selectedPart || {};
	const { replacementChain: replacementChainFromPartInfo } = partInfo || {};
	const replacementChain = replacementChainFromPart || replacementChainFromPartInfo;
	const { predecessorParts, successorParts } = replacementChain || {};

	const handleReplacementChain = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		dispatch(TOGGLE_REPLACEMENT_CHAIN_MODAL({ selectedPart, vin }));
	};

	return (
		(!isEmpty(predecessorParts) || !isEmpty(successorParts)) && (
			<FootnoteItem
				onClick={handleReplacementChain}
				colorOption={colorOption}
				className="vehicle-part__replacement-chain disp-flex"
				content={t('vehicles:show-previous-version')}
				iconType={IconType.UNDO}
				id={getComponentId(
					[Pages.VEHICLE, Components.PARTS, Components.FOOTNOTE, Buttons.OPEN, 'replacement-chain', partNumber],
					true,
					true
				)}
			/>
		)
	);
}
