import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import ISPTextButton, { TextColors, HoverTypes } from '@isp/text-button';
import { CodeValidityInfoItem } from '@Reducers/vehicle/models';
import { getCodeValidityFromDetails } from '@Helpers/webparts';
import './index.scss';

interface CodeValidityButtonProps {
	textColor?: TextColors;
	onClick(e): void;
	id: string;
	codes: string | CodeValidityInfoItem[];
}

const CodeValidityButton: VFC<CodeValidityButtonProps> = ({ textColor = TextColors.BLACK, onClick, id, codes }) => {
	const { t } = useTranslation(['vehicles']);
	const partCode = typeof codes === 'string' ? codes : getCodeValidityFromDetails(codes);
	const codeValidityLabel = `${t('vehicles:color-codes-code')}: ${partCode}`;

	return (
		<div className="code-validity__button m-t-1 w-100-p">
			<ISPTextButton
				color={textColor}
				hoverType={HoverTypes.AZURE}
				underline
				onClick={onClick}
				className="fs-16 code-validity__color-code"
				id={id}
			>
				{codeValidityLabel}
			</ISPTextButton>
		</div>
	);
};

export default CodeValidityButton;
