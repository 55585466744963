export enum PrintableDocument {
	ORDER,
	VEHICLE,
	EXPLOSION_DRAWING,
	PARTS_LIST,
	BOTH_EXPLOSION_DRAWING_AND_PARTS_LIST,
	PART_BASKET,
}

export const VEHICLE_PLATE_MAX_CHAR = 40;
export const VEHICLE_CUSTOMER_NAME_MAX_CHAR = 20;
export const TRUNCATE_OMISSION = '...';
export const PART_LIST_PRINT_LIMIT = 150;
