export enum DSBActivationContent {
	ACTIVATE_DSB = 'ACTIVATE_DSB',
	RELOAD_PAGE = 'RELOAD_PAGE',
}

export enum NextServiceLocation {
	MAIN = 'mainContainer',
	DETAIL = 'dsbDetail',
}

export enum DSBStatus {
	CHECKING = 'CHECKING',
	ACTIVATED = 'ACTIVATED',
	NOT_ACTIVATED = 'NOT_ACTIVATED',
	SERVICE_ERROR = 'SERVICE_ERROR',
}
