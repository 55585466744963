import { InputTypes, VIDEO_EXTENSIONS, WindowTargetAttributes } from '@Constants/common';

export const getUrlExtension = (url: string) => {
	const periodInd = url?.lastIndexOf('.');
	return url?.substring(periodInd + 1);
};

export const isVideo = (mediaURL: string) => {
	return VIDEO_EXTENSIONS.includes(getUrlExtension(mediaURL));
};

export const downloadFile = (file, fileName, MIMEType?) => {
	const fileURL = URL.createObjectURL(new Blob([file], { type: MIMEType }));
	const a = document.createElement('a');
	a.target = WindowTargetAttributes.BLANK;
	a.download = fileName;
	a.href = fileURL;
	a.click();
};

export const openFilePicker = (acceptedTypes: string[] = undefined, setUploadedFile?: (file) => void) => {
	const input = document.createElement('input');
	input.type = InputTypes.FILE;
	input.onchange = e => {
		const target = e.target as HTMLInputElement;
		if (setUploadedFile) setUploadedFile(target.files[0]);
	};
	if (acceptedTypes) input.accept = acceptedTypes.join();
	input.click();
};
