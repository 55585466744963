import { GoToUrl } from '@Helpers/ExternalURLs';
import { getRoutesAs } from '@Routes';
import Router from 'next/router';
import { Patterns, WindowTargetAttributes } from '@Constants/common';

export const handleLinkoutRedirection = (
	linkout,
	route,
	parameters = {},
	windowTarget: WindowTargetAttributes = WindowTargetAttributes.BLANK
) => {
	if (linkout) {
		const isProtocolIncluded = new RegExp(Patterns.URL).test(linkout);

		if (isProtocolIncluded) {
			GoToUrl(linkout, windowTarget);
		} else {
			Router.push(route, getRoutesAs(route, parameters));
		}
	}
};
