import { VFC, useRef, useState } from 'react';
import NextI18Next from '@Lib/i18n';
import Tab from '@isp/tab';
import Tabs, { TabsMobileDisplayOption, TabsThemes } from '@isp/tabs';
import CMSContent, { CMSContentThemes } from '@Shared/cms-content/CMSContent';
import { PlatformPreviewData } from '@Umbraco/home';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { useAnimation } from '@Helpers/hooks/useAnimation';
import { Media } from '@Shared/media';
import ModuleHeader from '../Shared/ModuleHeader';

const PlatformPreviewModule: VFC<PlatformPreviewData> = ({ tabs, title }) => {
	const [activeTabId, setActiveTabId] = useState(tabs[0]?.id);
	const boxWrapper = useRef<HTMLDivElement>();
	const language = geti18nLanguage(NextI18Next.i18n.language);
	useAnimation(boxWrapper);

	return (
		<div className="home__modules p-h-10 m-auto" ref={boxWrapper}>
			<ModuleHeader title={title} />
			<div className="home__modules__platform-preview">
				<Tabs
					theme={TabsThemes.LIGHT}
					activeTabId={activeTabId}
					mobileDisplay={TabsMobileDisplayOption.ACCORDION}
					accordionClassName="w-100-p"
					accordionViewClassName="br-4 bg-white-smoke m-t-2 p-5 w-100-p"
				>
					{tabs.map(tab => (
						<Tab
							key={tab.id}
							tabId={tab.id}
							title={tab.tabTitle}
							onTabClick={() => setActiveTabId(tab.id === activeTabId ? null : tab.id)}
							linkClassName="DaimlerCS-Bold fs-16 color-black-two"
						>
							<div className="disp-grid train__center">
								<CMSContent
									theme={CMSContentThemes.DARK}
									content={tab.description}
									contentClassName="text-center p-0 fs-16"
								/>
								{tab.media && (
									<Media
										language={language}
										media={tab.media}
										className="platform-preview__media disp-flex train__justify-center"
									/>
								)}
							</div>
						</Tab>
					))}
				</Tabs>
			</div>
		</div>
	);
};

export default PlatformPreviewModule;
