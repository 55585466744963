import { useEffect, useState } from 'react';
import { userSettingsDb } from '@Dexie/userSettingsDB';
import { useLiveQuery } from 'dexie-react-hooks';

const useUserSettings = () => {
	const [isVehiclePartsListView, setIsVehiclePartsListview] = useState(null);
	const [useShowNetPriceShortcut, setUseShowNetPriceShortcut] = useState(null);
	const [selectedPartLanguage, setSelectedPartLanguage] = useState(null);
	const [contentLanguages, setContentLanguages] = useState(null);
	const [selectedWebPartsOrderType, setSelectedWebPartsOrderType] = useState(null);
	const [userSettings, userSettingsLoaded] = useLiveQuery(
		() => userSettingsDb.userSettingsData.toArray().then(data => [data[data?.length - 1], true]),
		[],
		[]
	);

	useEffect(() => {
		if (userSettings && userSettingsLoaded) {
			setIsVehiclePartsListview(userSettings.isVehiclePartsListView);
			setUseShowNetPriceShortcut(userSettings.useShowNetPriceShortcut);
			setSelectedPartLanguage(userSettings.selectedPartLanguage);
			setContentLanguages(userSettings.contentLanguages);
			setSelectedPartLanguage(userSettings.selectedPartLanguage);
			setSelectedWebPartsOrderType(userSettings.selectedWebPartsOrderType);
		}
	}, [userSettingsLoaded, userSettings]);

	return {
		isVehiclePartsListView,
		useShowNetPriceShortcut,
		selectedPartLanguage,
		contentLanguages,
		selectedWebPartsOrderType,
	};
};

export default useUserSettings;
