import { ReactElement } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import { renderToStaticMarkup } from 'react-dom/server';
import parse from 'html-react-parser';
import { client } from '@Umbraco/client';
import { transform } from '@Umbraco/helpers/transform';
import getHierarchicalDescendants from '@Umbraco/helpers';
import CONFIG from '@Umbraco/config.json';
import { Image, toImage } from '@Umbraco/models';
import { chain } from '@Helpers/common/array';

export enum PartTypeKeys {
	COLLISION = 'COLLISION',
	ELECTRICS = 'ELECTRICS',
	ENGINE = 'ENGINE',
	MAINTENANCE = 'MAINTENANCE',
	REPAIR = 'REPAIR',
	SUSPENSION = 'SUSPENSION',
	TRANSMISSION = 'TRANSMISSION',
}

export interface Product {
	id: string;
	title: string;
	headline: string;
	label: string;
	images: Image[];
	icon: string;
	details: string;
	webpartsLink: string;
	webpartsCategoryName: string;
	view3DLink: string;
	vehicleTypes: string[];
	categories: string[];
	partTypes: string[];
	partTypesKeys: PartTypeKeys[];
	partTypesLabels: string[];
}

export const toVehicleType = item => ({
	id: item._id,
	icon: item.icon,
	label: item.label,
});

export const toCategory = item => ({
	id: item._id,
	label: item.label,
});

export const toPartType = item => ({
	id: item._id,
	key: item.key,
	label: item.label,
});

export const getVehicleTypes = vehicleTypes => {
	if (vehicleTypes) {
		return isArray(vehicleTypes) ? vehicleTypes.map(toVehicleType) : [toVehicleType(vehicleTypes)];
	}
	return [];
};

export const getCategories = categories => {
	if (categories) {
		return isArray(categories) ? categories.map(toCategory) : [toCategory(categories)];
	}
	return [];
};

export const getPartTypes = partTypes => {
	if (partTypes) {
		return isArray(partTypes) ? partTypes.map(toPartType) : [toPartType(partTypes)];
	}
	return [];
};

export const toProduct = item => ({
	id: item._id,
	title: item.title,
	headline: item.headline,
	label: item.label,
	images: get(item, 'images', []).map(toImage),
	icon: item.icon,
	details: renderToStaticMarkup(parse(item.details, { replace: transform }) as ReactElement),
	webpartsLink: item.webpartsLink,
	view3DLink: item.view3DLink,
	webpartsCategoryName: item.webpartsCategoryName,
	vehicleTypes: chain(getVehicleTypes(get(item, 'vehicleTypes', [])))
		.map(vt => vt.id)
		.value(),
	categories: chain(getCategories(get(item, 'categories', [])))
		.map(c => c.id)
		.value(),
	partTypes: chain(getPartTypes(get(item, 'partTypes', [])))
		.map(pt => pt.id)
		.value(),
	partTypesKeys: chain(getPartTypes(get(item, 'partTypes', [])))
		.map(pt => pt.key)
		.value(),
	partTypesLabels: chain(getPartTypes(get(item, 'partTypes', [])))
		.map(pt => pt.label)
		.value(),
});

export default function getProductFilterConfiguration(key: string, market: string, lang: string) {
	const id = CONFIG[key];

	return client(`/content/${id}/descendants/1/1000`, market, lang)
		.then(response => {
			const descendants = get(response, '_embedded.content');
			const content = getHierarchicalDescendants(id, descendants);
			const vehicleTypes = get(find(content.children, { contentTypeAlias: 'productVehicleTypes' }), 'children', []);
			const categories = get(find(content.children, { contentTypeAlias: 'productCategories' }), 'children', []);
			const partTypes = get(find(content.children, { contentTypeAlias: 'productPartTypes' }), 'children', []);

			return {
				vehicleTypes: getVehicleTypes(vehicleTypes),
				categories: getCategories(categories),
				partTypes: getPartTypes(partTypes),
			};
		})
		.catch(err => {
			throw err;
		});
}
