import { useEffect, useRef } from 'react';

const useInterval = (callback, duration, delay = false) => {
	const intervalRef = useRef<NodeJS.Timeout>();

	useEffect(() => {
		setTimeout(
			() => {
				intervalRef.current = setInterval(() => {
					callback();
				}, duration);
			},
			delay ? duration : 0
		);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);
};

export default useInterval;
