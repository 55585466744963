import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { LinkVariant } from '@isp/link';
import ISPLinkWithIcon from '@isp/link-with-icon';
import { useTranslation } from '@Lib/i18n';
import { RootState } from '@Redux';
import { downloadFile } from '@Helpers/common/media';
import useClickOutside from '@Helpers/hooks/useClickOutside';
import { getPartsOrderXFRFile, getPartsOrderXLSXFile } from '@Apis/orders';
import { FileExtensions, MIMETypes } from '@Constants/common';

const OrderExport = ({ orderId }) => {
	const { t } = useTranslation(['common', 'orders']);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const userId = useSelector<RootState, string>(state => state.user.userId);
	const menuWrapperRef = useRef<HTMLDivElement>(null);

	useClickOutside(menuWrapperRef, isClicked => setMenuOpen(!isClicked));

	const handleXLSXExport = () => {
		setLoading(true);
		getPartsOrderXLSXFile(orderId)
			.then(file => downloadFile(file, `${userId}${FileExtensions.XLSX}`.toUpperCase(), MIMETypes.XLSX))
			.finally(() => setLoading(false));
	};

	const handleXFRExport = () => {
		setLoading(true);
		getPartsOrderXFRFile(orderId)
			.then(file => downloadFile(file, `${userId}${FileExtensions.XFR}`.toUpperCase()))
			.finally(() => setLoading(false));
	};

	const getMenuOptionClassName = () =>
		classNames('order-export__menu__option h-46-px w-100-p p-h-2 train train__center cursor-pointer', {
			'op-4 pointer-events-none': loading,
		});

	const arrowIconType = menuOpen ? IconType.ARROW_UP : IconType.ARROW_DOWN;

	return (
		<div className="order-export">
			<ISPLinkWithIcon
				iconType={IconType.DOWNLOAD2}
				variant={LinkVariant.SECONDARY}
				className="DaimlerCS-Bold"
				onClick={() => setMenuOpen(!menuOpen)}
			>
				{t('common:export')}
				<Icon size={IconSize.SMALLER} type={arrowIconType} variant={IconVariant.BLACK} />
			</ISPLinkWithIcon>
			{menuOpen && (
				<div className="pos-rel" ref={menuWrapperRef}>
					<div className="order-export__menu bg-white br-4 pos-abs h-93-px" data-box>
						<div className={getMenuOptionClassName()} onClick={handleXFRExport}>
							<div className="order-export__menu__option-text DaimlerCS-Bold">{t('orders:export-xfr-format')}</div>
						</div>
						<div className="bg-very-light-pink-3 w-100-p h-1-px" />
						<div className={getMenuOptionClassName()} onClick={handleXLSXExport}>
							<div className="order-export__menu__option-text DaimlerCS-Bold">{t('orders:export-xlsx-format')}</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrderExport;
