import floor from 'lodash/floor';
import get from 'lodash/get';
import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import serviceEndpoints from '@Service-endpoints';
import { PERCENTAGE, Patterns } from '@Constants/common';

export const getMediaUrl = path => {
	const env = getAppEnvironment();
	if (new RegExp(Patterns.URL).test(path)) return path;
	return get((serviceEndpoints as any).config[env], 'cmsUrl', '').concat(path);
};

const POSITION_CENTER = 50;

export const getFocalPointClass = (top: number, left: number, element: 'image' | 'background' = 'image'): string => {
	const classPrefix = element === 'image' ? 'object-pos' : 'bg-pos';

	const y = floor(top * PERCENTAGE) || POSITION_CENTER;
	const x = floor(left * PERCENTAGE) || POSITION_CENTER;

	return element === 'image' ? `${classPrefix}-${y}-${x}` : `${classPrefix}-y-${y} ${classPrefix}-x-${x}`;
};
