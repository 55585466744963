import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import { UserRoles } from '@Constants/user';

const useIsUserASP = () => {
	const userRoles = useSelector<RootState, UserRoles[]>(state => state.user.role);
	return userRoles?.includes(UserRoles.ASP);
};

export default useIsUserASP;
