/* Common interfaces for CMS */

export interface Link {
	linkUrl: string;
	linkCaption: string;
}

export interface Image {
	altText?: string;
	url: string;
	focalPointTop?: string;
	focalPointLeft?: string;
}

export interface Video {
	id: number;
	altText?: string;
	url: string;
}

export const toVideo = item => ({
	id: item?.Id,
	url: item?._url || '',
	altText: item?.AltText || '',
});

export const toImage = item => ({
	url: item?._url || '',
	altText: item?.AltText || '',
	focalPointLeft: item?.FocalPointLeft || '',
	focalPointTop: item?.FocalPointTop || '',
});

export const toLinkItem = item => ({
	linkUrl: item?.linkUrl || '',
	linkCaption: item?.linkCaption || '',
});
