import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import { UserRoles } from '@Constants/user';

const ISPUser = ({ children }) => {
	const userRoles = useSelector<RootState, UserRoles[]>(state => state.user.role);
	const userISP = !userRoles?.includes(UserRoles.ASP);

	return userISP ? children : '';
};

export default ISPUser;
