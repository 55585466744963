import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from '@Lib/i18n';
import ISPButton, { ButtonVariant } from '@isp/button';
import ISPIcon, { IconType, IconVariant } from '@isp/icon';
import ISPTooltip, { TooltipPositions, TooltipThemes } from '@isp/tooltip';
import { RootState } from '@Redux';
import { SET_SHOW_NET_PRICE } from '@Reducers/shop/actions';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { getShowNetPrices } from '@Helpers/webparts';
import useUserSettings from '@Helpers/hooks/useUserSettings';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import { Events } from '@Constants/common';
import DATA_QA from '@Constants/style/data-qa-selector.json';

const NetPriceToggle = () => {
	const { t } = useTranslation(['account']);
	const dispatch = useDispatch();
	const [hovered, setHovered] = useState(false);
	const netPricesAvailable = useSelector<RootState, boolean>(state => state.shop.showNetPrice);
	const { useShowNetPriceShortcut } = useUserSettings();
	const descriptionRef = createRef<HTMLDivElement>();

	const handleShowNetPriceClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		dispatch(SET_SHOW_NET_PRICE(!getShowNetPrices()));
	};

	const showHideNetPriceEventHandler = e => {
		if (e.target.type === undefined && !e.repeat && (e.key === 'h' || e.key === 'H'))
			dispatch(SET_SHOW_NET_PRICE(!getShowNetPrices()));
	};

	useEffect(() => {
		if (useShowNetPriceShortcut) window.addEventListener(Events.KEY_DOWN, showHideNetPriceEventHandler, true);

		return () => {
			if (useShowNetPriceShortcut) window.removeEventListener(Events.KEY_DOWN, showHideNetPriceEventHandler, true);
		};
	}, [netPricesAvailable, useShowNetPriceShortcut]);

	useEffect(() => {
		dispatch(SET_SHOW_NET_PRICE(getShowNetPrices()));
	}, []);

	useEffect(() => {
		if (hovered) {
			ReactTooltip.show(descriptionRef.current);
		} else {
			ReactTooltip.hide(descriptionRef.current);
		}
	}, [hovered]);

	return (
		netPricesAvailable !== null && (
			<div>
				<div
					data-hover-azure
					data-tip=""
					ref={descriptionRef}
					data-effect="solid"
					data-for={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.TOGGLE, '-show-net-prices-tooltip'])}
					data-qa={DATA_QA.NET_PRICE_TOGGLE}
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
				>
					<ISPButton
						onClick={handleShowNetPriceClick}
						variant={ButtonVariant.DEFAULT}
						id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.TOGGLE, '-show-net-prices'])}
					>
						<ISPIcon
							type={netPricesAvailable ? IconType.NETPRICE_HIDE : IconType.NETPRICE_SHOW}
							variant={IconVariant.BLACK_TWO}
						/>
					</ISPButton>
				</div>
				{hovered && (
					<ISPTooltip
						theme={TooltipThemes.DARK}
						place={TooltipPositions.BOTTOM}
						id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.TOGGLE, '-show-net-prices-tooltip'])}
						getContent={() => <p>{t('account:show-net-price-tooltip')}</p>}
					/>
				)}
			</div>
		)
	);
};

export default NetPriceToggle;
