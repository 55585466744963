import isEmpty from 'lodash/isEmpty';
import { getCatalogWithAggregates } from '@Apis/catalogue';
import {
	getCatalogueFilterOptionsFromSessionStorage,
	setCatalogueFilterOptionsToSessionStorage,
} from '@Helpers/webparts';
import { actionTypes } from '@Reducers/catalogue/actionTypes';
import { initialCatalogueFilterOptionsState } from '@Constants/webparts';
import { VehicleCatalogFilterOptions } from '@Constants/vehicle';
import { CataloguePathRecord } from './models';

export const SET_CAMPAIGNS = campaigns => dispatch => {
	return dispatch({
		type: actionTypes.SET_CAMPAIGNS,
		campaigns,
	});
};

export const SET_NONSAVED_VEHICLE = nonSavedVehicleInformation => dispatch => {
	return dispatch({
		type: actionTypes.SET_NONSAVED_VEHICLE,
		nonSavedVehicleInformation,
	});
};

export const SET_SEARCHED_VEHICLE_STATUS = searchedVehicleStatus => dispatch => {
	return dispatch({
		type: actionTypes.SET_SEARCHED_VEHICLE_STATUS,
		searchedVehicleStatus,
	});
};

export const GET_CATALOGS = ({ selectedPartLanguage = null, model = null }) => async (dispatch, getState) => {
	const modelId = model || getState().catalogue?.selections?.modelId;
	return getCatalogWithAggregates(modelId, selectedPartLanguage)
		.then(resp => {
			const { catalogs } = resp;
			const isModelOldVehicle = !isEmpty(catalogs) && !catalogs[0].dataCardExists;
			dispatch({
				type: actionTypes.GET_CATALOGS,
				vehicleDataCardAvailable: catalogs[0].datacardExists,
				catalogs,
			});
			return { isModelOldVehicle, catalogs };
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_CATALOGS,
				vehicleDataCardAvailable: false,
				catalogs: null,
			});
			return { isModelOldVehicle: false, catalogs: null };
		});
};

export const SET_CATALOGUE_VIN = (VIN: string) => {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_CATALOGUE_VIN,
			catalogueVIN: VIN,
		});

		return Promise.resolve();
	};
};

export const SET_SEARCH_TYPE = searchType => dispatch => {
	dispatch({
		type: actionTypes.SET_SEARCH_TYPE,
		searchType,
	});
	return Promise.resolve();
};

export const SET_CATALOGUE_FILTER_OPTIONS = (params: {
	catalogueFilterOptions?: VehicleCatalogFilterOptions;
	VIN?: string;
}) => dispatch => {
	const { catalogueFilterOptions, VIN } = params;
	let finalCatalogueFilterOptions: VehicleCatalogFilterOptions = null;

	switch (true) {
		// If there is VIN and there is no catalogueFilterOption in params(INITIALIZE CATALOGUE)
		// Get catalogue filter options from sessionStorage
		case VIN && !catalogueFilterOptions: {
			const fromStorage = getCatalogueFilterOptionsFromSessionStorage(VIN);
			// If there is no item in session storage for initialize it will set it to inital state
			finalCatalogueFilterOptions = fromStorage || initialCatalogueFilterOptionsState;
			break;
		}
		// If there is VIN and there is catalogueFilterOption in params(VIN CATALOGUE)
		// Set an item in sessionStorage for catalogue filter options from param
		case VIN && !!catalogueFilterOptions:
			finalCatalogueFilterOptions = catalogueFilterOptions;
			setCatalogueFilterOptionsToSessionStorage(catalogueFilterOptions, VIN);
			break;
		// If there is no VIN and There is catalogueFilterOption in params(NO VIN CATALOGUE)
		// set catalogue filter options from param and not set SessionStorage
		case !VIN && !!catalogueFilterOptions:
			finalCatalogueFilterOptions = catalogueFilterOptions;
			break;
		default:
			finalCatalogueFilterOptions = initialCatalogueFilterOptionsState;
			break;
	}
	dispatch({
		type: actionTypes.SET_CATALOGUE_FILTER_OPTIONS,
		catalogueFilterOptions: finalCatalogueFilterOptions,
	});
};

export const SET_IS_MODEL_OLD_VEHICLE = (isModelOldVehicle: boolean) => dispatch => {
	dispatch({
		type: actionTypes.SET_IS_MODEL_OLD_VEHICLE,
		isModelOldVehicle,
	});
};

export const SET_SELECTED_SUBGROUP_INDEX = (subGroupIndex: number) => {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_SELECTED_SUBGROUP_INDEX,
			subGroupIndex,
		});
	};
};

export const UPDATE_CATALOGUE_PATH = (path: CataloguePathRecord) => (dispatch, getState) => {
	const oldPath = getState().catalogue?.path;
	dispatch({
		type: actionTypes.UPDATE_CATALOGUE_PATH,
		path: { ...oldPath, ...path },
	});
};

export const RESET_CATALOGUE_PATH = () => dispatch => {
	dispatch({
		type: actionTypes.RESET_CATALOGUE_PATH,
		path: null,
	});
};
