import get from 'lodash/get';
import truncate from 'lodash/truncate';
import { useTranslation } from '@Lib/i18n';
import Icon, { IconSize, IconType } from '@isp/icon';
import '@Shared/header/IncidentManagementBanner/index.scss';
import { INCIDENT_NOTIFICATION_DESCRIPTION_MAX_CHAR } from '@Constants/notifications';

const BannerCard = ({
	title,
	description,
	showReadMore = false,
	onReadMore,
	iconType = IconType.INFO_CIRCLE,
	iconVariant,
	iconSize = IconSize.SMALL,
}) => {
	const { t } = useTranslation(['common']);
	const stringTruncated = get(description, 'length', 0) > INCIDENT_NOTIFICATION_DESCRIPTION_MAX_CHAR;

	const getBannerDescription = () => {
		return (
			<>
				{truncate(description, { length: INCIDENT_NOTIFICATION_DESCRIPTION_MAX_CHAR, omission: '...' })}
				{(stringTruncated || showReadMore) && (
					<span className="m-l-1 description-read-more" onClick={onReadMore}>
						{t('read-more')}
					</span>
				)}
			</>
		);
	};

	return (
		<div className="disp-flex grid--item-gap-4 train__center">
			<div className="h-30-px train__justify-center">
				<Icon type={iconType} variant={iconVariant} size={iconSize} />
			</div>
			<div className="disp-flex grid--item-gap-4">
				<p className="DaimlerCS-Bold fs-16">{title}</p>
				<p className="description fs-16">{getBannerDescription()}</p>
			</div>
		</div>
	);
};

export default BannerCard;
