import { ReactElement } from 'react';
import get from 'lodash/get';
import { renderToStaticMarkup } from 'react-dom/server';
import { transform } from '@Umbraco/helpers/transform';
import parse from 'html-react-parser';
import { client } from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';

export interface DataProtectionTabItem {
	id: string;
	identifier: string;
	title: string;
	content: string;
	version: number;
	visibleInTermsConditionModal: boolean;
}

export interface DataProtection {
	tabItems: DataProtectionTabItem[];
	version: string;
}

export const toTabItem = item => ({
	id: item._id,
	title: item.tabTitle,
	identifier: item.identifier,
	isVisible: item.visibleInTermsConditionModal,
	content: renderToStaticMarkup(parse(get(item, 'text', ''), { replace: transform }) as ReactElement),
});

export const toTermsAndDataProtectionData = item => ({
	version: item.version,
	tabItems: item.tabItems.map(toTabItem),
});

const filterVisibleTabItems = tabItems =>
	tabItems.filter((tab: DataProtectionTabItem) => tab.visibleInTermsConditionModal);

export function getTermsAndDataProtectionTabItems(market: string, lang: string) {
	const id = CONFIG.IMPRINT[market.toUpperCase()];

	return client(`/content/${id}/children/1/1000`, market, lang)
		.then(response => {
			return get(response, '_embedded.content');
		})
		.catch(err => {
			throw err;
		});
}
export function getTermsAndDataProtectionData(market: string, lang: string) {
	const id = CONFIG.IMPRINT[market.toUpperCase()];

	return client(`/content/${id}`, market, lang)
		.then(async response => {
			const { version } = response;
			const tabItems = await getTermsAndDataProtectionTabItems(market, lang);

			return toTermsAndDataProtectionData({ tabItems: filterVisibleTabItems(tabItems), version });
		})
		.catch(err => {
			throw err;
		});
}
