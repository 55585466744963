import { connect } from 'react-redux';

const withISPUser = WrappedComponent => {
	function ISPUserComponent(props) {
		const { isASPUser } = props;
		if (isASPUser) {
			return null;
		}
		return <WrappedComponent {...props} />;
	}
	const mapStateToProps = state => {
		return {
			isASPUser: state.user.isASPUser,
		};
	};
	return connect(mapStateToProps)(ISPUserComponent);
};
export default withISPUser;
