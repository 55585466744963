import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import { VehicleMoreInfoType } from '@Constants/vehicle';

const VehicleEditModal = dynamic(() => import('../VehicleModals/VehicleEditModal'), { ssr: false });
const VehicleMoreInfoModal = dynamic(() => import('../VehicleMoreInfoModal'), { ssr: false });

const VehicleModals = ({ showVehicleEditModal, showVehicleMoreInfoModal }) => {
	return (
		<>
			{showVehicleMoreInfoModal && <VehicleMoreInfoModal />}
			{showVehicleEditModal && <VehicleEditModal />}
		</>
	);
};

const mapStateToProps = state => ({
	showVehicleEditModal: state.modals.showVehicleEditModal,
	showVehicleMoreInfoModal: state.modals.vehicleMoreInfoType !== VehicleMoreInfoType.NOT_SET,
});

export default connect(mapStateToProps)(VehicleModals);
