import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { useRouter } from 'next/router';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon, { IconType, IconVariant, IconSize } from '@isp/icon';
import Button, { ButtonVariant } from '@isp/button';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { handleLinkoutRedirection } from '@Helpers/redirection/handleLinkoutRedirection';
import routes from '@Routes';
import { getFocalPointClass } from '@Umbraco/media';
import { CampaignTheme } from '@Umbraco/sales-offers';
import { useWindowIntersect } from '@isp/sticky/useWindowIntersect';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Buttons, Components, getComponentId } from '@Constants/google-analytics';
import { WindowTargetAttributes } from '@Constants/common';
import './index.scss';

const CampaignBanner = ({ campaignItems, className = '', scaleImage = false, autoplay = false }) => {
	const sliderRef = useRef(null);
	const campaignBannerRef = useRef(null);
	const [loop, setLoop] = useState(true);
	const [currentSlide, setCurrentSlide] = useState(0);
	const campaignBannerInViewport = useWindowIntersect(campaignBannerRef);

	const onPreviousClick = e => {
		if (!sliderRef.current) return;
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		sliderRef.current.slickPrev();
	};

	const onNextClick = e => {
		if (!sliderRef.current) return;
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		sliderRef.current.slickNext();
	};

	const onDotClick = (e, index) => {
		if (!sliderRef.current) return;
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		sliderRef.current.slickGoTo(index);
	};

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SliderArrow direction={IconType.ARROW_RIGHT} />,
		prevArrow: <SliderArrow direction={IconType.ARROW_LEFT} />,
		beforeChange: (_, newIndex) => {
			setCurrentSlide(newIndex);
		},
		afterChange: current => {
			if (current === campaignItems.length - 1) {
				setLoop(false);
			}
		},
		customPaging: () => (
			<div className="pager__item">
				<span />
			</div>
		),
	};

	useEffect(() => {
		if (campaignBannerInViewport) {
			setCurrentSlide(0);
			sliderRef.current.slickGoTo(0);
		}
	}, [campaignBannerInViewport]);

	return (
		<div className={classNames(className, 'banner w-100-p')} ref={campaignBannerRef}>
			<Slider {...settings} autoplay={autoplay && loop} ref={sliderRef}>
				{campaignItems?.map((item, index) => (
					<CampaignItem {...item} key={index} scaleImage={scaleImage} />
				))}
			</Slider>
			{campaignItems?.length > 1 && (
				<div className="train__justify-center banner__custom-iteration">
					<span
						onClick={onPreviousClick}
						id={getComponentId([Components.MARKETING_CAMPAIGN, Buttons.PREVIOUS, Buttons.MOBILE])}
					>
						<Icon type={IconType.ARROW_LEFT} variant={IconVariant.WHITE} size={IconSize.LARGE} />
					</span>
					<span className="disp-flex train__justify-center train__center">
						{campaignItems.map((_, index) => (
							<div
								className={`pager__item${index === currentSlide ? '--active' : ''}`}
								key={`pager__item-${index}`}
								id={getComponentId([Components.MARKETING_CAMPAIGN, Buttons.SLIDER_DOT, Buttons.MOBILE, index])}
								onClick={e => onDotClick(e, index)}
							>
								<span />
							</div>
						))}
					</span>
					<span
						onClick={onNextClick}
						id={getComponentId([Components.MARKETING_CAMPAIGN, Buttons.NEXT, Buttons.MOBILE])}
					>
						<Icon type={IconType.ARROW_RIGHT} variant={IconVariant.WHITE} size={IconSize.LARGE} />
					</span>
				</div>
			)}
		</div>
	);
};

const CampaignItem = ({
	title,
	description,
	media,
	icon,
	button,
	scaleImage,
	campaignTheme = CampaignTheme.DARK,
	onClick,
}) => {
	const { market } = useRouter().query;
	const showMedia = media && media.url && campaignTheme !== CampaignTheme.ICONS;
	const showIcon = !showMedia && !!icon;
	const buttonAvailable = button && button.label && (button.url || onClick);

	const getCampaignVariantClass = () => {
		switch (true) {
			case showMedia:
				return '';
			case showIcon:
				return '-icon';
			default:
				return '-text';
		}
	};

	const onClickButton = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		if (button?.url) {
			const splittedUrl = button.url.split('/').filter(item => !!item);
			handleLinkoutRedirection(
				button.url,
				splittedUrl.length > 2 ? routes.NEWS_DETAIL : routes.NEWS,
				{ market, id: splittedUrl.length > 2 ? splittedUrl.pop() : '' },
				WindowTargetAttributes.SELF
			);
		} else {
			onClick(e);
		}
	};

	return (
		<div
			className={`banner__item banner__item--theme-${campaignTheme.toLowerCase()}${getCampaignVariantClass()}`}
			data-no-media={!showMedia && !showIcon}
		>
			{showIcon && (
				<Icon type={IconType[icon]} variant={IconVariant.WHITE} className="banner__item--theme-dark-icon__image" />
			)}
			{showMedia && (
				<div className="banner__item__image-container" data-scale-image={scaleImage}>
					<img
						data-scale-image={scaleImage}
						src={media.url}
						alt={media.altText}
						className={getFocalPointClass(media.focalPointTop, media.focalPointLeft)}
					/>
				</div>
			)}
			<div className="banner__item__content p-v-1" data-no-media={!showMedia}>
				<div className="banner__item__text-content">
					{showIcon && <Icon type={IconType[icon]} variant={IconVariant.MARINE_BLUE} className="banner__item__icon" />}
					<span>
						<p className="DaimlerCAC-Regular banner__item__title fs-38" data-no-media={!showMedia && !showIcon}>
							{title}
						</p>
						{description && <p className="banner__item__description">{description}</p>}
					</span>
				</div>
				{buttonAvailable && (
					<Button
						className="banner__item__button"
						variant={campaignTheme === CampaignTheme.LIGHT ? ButtonVariant.FLAT : ButtonVariant.TERTIARY_BLACK}
						onClick={onClickButton}
						id={getComponentId([Components.MARKETING_CAMPAIGN, Buttons.GO_TO_URL])}
					>
						{button.label}
					</Button>
				)}
			</div>
		</div>
	);
};

const SliderArrow = props => {
	const { className, onClick, direction } = props;
	const directionIdMapping = {
		[IconType.ARROW_RIGHT]: {
			id: getComponentId([Components.MARKETING_CAMPAIGN, Buttons.NEXT]),
			dataQA: DATA_QA.QUOTE_MODULE_NEXT_ARROW,
		},
		[IconType.ARROW_LEFT]: {
			id: getComponentId([Components.MARKETING_CAMPAIGN, Buttons.PREVIOUS]),
			dataQA: DATA_QA.QUOTE_MODULE_PREVIOUS_ARROW,
		},
	};

	return (
		<div className={className} onClick={onClick} id={directionIdMapping[direction]?.id}>
			<Icon type={direction} variant={IconVariant.WHITE} size={IconSize.LARGE} />
		</div>
	);
};

export default CampaignBanner;
