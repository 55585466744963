import { actionTypes } from '@Reducers/faq/actionTypes';
import { TOGGLE_SUPPORT_MODAL } from '@Reducers/modals/actions';
import { clearSupportModalStorageKey } from '@Helpers/support';
import { getFAQCategory } from '@Umbraco/faq';
import { RouteAvailableSoftwareProducts } from '@Constants/faq';
import { ProductRoute } from './models';

export const CONTINUE_SUPPORT_MODAL = supportData => dispatch => {
	dispatch({
		type: actionTypes.CONTINUE_SUPPORT_MODAL,
		supportData,
	});
	dispatch(TOGGLE_SUPPORT_MODAL());
};

export const COMPLETE_SUPPORT_MODAL = () => dispatch => {
	dispatch({
		type: actionTypes.COMPLETE_SUPPORT_MODAL,
		supportData: null,
	});
	clearSupportModalStorageKey();
};

export const GET_PRODUCT_ROUTES = (id, market, language, slug) => dispatch => {
	dispatch({
		type: actionTypes.GET_PRODUCT_ROUTES,
		productRoutes: [],
	});
	return getFAQCategory(id, market, language, slug).then(res => {
		const updatedRoutes = res.childCategories
			.filter(childCategory => childCategory.subcategoryIdentifier in RouteAvailableSoftwareProducts)
			.map(childCategory => {
				const productRoute: ProductRoute = {
					productKey: childCategory.subcategoryIdentifier,
					slug: childCategory.slug,
				};
				return productRoute;
			});

		dispatch({ type: actionTypes.GET_PRODUCT_ROUTES, productRoutes: updatedRoutes });
	});
};
