import get from 'lodash/get';
import dayjs from 'dayjs';
import client from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { toImage } from '@Umbraco/models';

export enum CampaignTheme {
	DARK = 'DARK',
	LIGHT = 'LIGHT',
	ICONS = 'ICONS',
}

const toSaleOffer = item => ({
	id: item?._id,
	title: item?.title,
	description: item?.description,
	media: toImage(item?.media),
	icon: item?.icon,
	button: { label: item?.button[0]?.label, url: item?.button[0]?.buttonUrl },
	campaignTheme: CampaignTheme[item?.campaignTheme],
	expiryDate: new Date(item?.expiryDate),
});

export default function getSalesOffers(market: string, lang: string) {
	const id = CONFIG.SALES_OFFERS;

	const filterNonExpiredOffers = offers => offers.filter(item => dayjs(item?.expiryDate).isAfter(dayjs()));

	return client(`/content/${id}/children/1/1000`, market, lang)
		.then(async response => {
			const offers = get(response, '_embedded.content', []);

			return filterNonExpiredOffers(offers?.map(toSaleOffer));
		})
		.catch(err => {
			throw err;
		});
}
