import { useState } from 'react';
import { postFeedback } from '@Apis/customer';
import { triggerScrollEvent } from '@Helpers/common/document';
import { APP_VERSION } from '@Constants/common';
import AdditionalFeedback from './AdditionalFeedback';
import InitialFeedback from './InitialFeedback';

enum FeedbackViewState {
	INITIAL,
	ADDITIONAL,
}

const Feedback = ({ className }) => {
	const [viewState, setViewState] = useState(FeedbackViewState.INITIAL);
	const [feedbackId, setFeedbackId] = useState('');

	const onThumbClick = value => {
		const feedbackData = {
			feedbackValue: value,
			url: window.location.href,
			releaseVersion: APP_VERSION,
		};
		return postFeedback(feedbackData).then(id => {
			setFeedbackId(id);
			setViewState(FeedbackViewState.ADDITIONAL);
			triggerScrollEvent();
		});
	};

	const onAdditionalNoteSubmit = notes => {
		const feedbackData = {
			notes,
			id: feedbackId,
		};
		return postFeedback(feedbackData).then(id => {
			setFeedbackId(id);
			setViewState(FeedbackViewState.ADDITIONAL);
			triggerScrollEvent();
		});
	};

	const ViewMap = {
		[FeedbackViewState.INITIAL]: <InitialFeedback onThumbClick={onThumbClick} />,
		[FeedbackViewState.ADDITIONAL]: <AdditionalFeedback onSubmit={onAdditionalNoteSubmit} />,
	};

	return (
		<div className={`disp-flex train__center train__justify-center feedback__contianer ${className}`}>
			{ViewMap[viewState]}
		</div>
	);
};

export default Feedback;
