export type DataState<T> = {
	loading: boolean;
	error: boolean;
	data: T | undefined | null;
};

export const getDataState = <T>(data: T | undefined | null): DataState<T> => ({
	loading: data === undefined,
	error: data === null,
	data,
});
