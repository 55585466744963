import get from 'lodash/get';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { CONTINUE_SUPPORT_MODAL, COMPLETE_SUPPORT_MODAL } from '@Reducers/faq/actions';
import { rtsSupportModalCreatePublicTicket, rtsSupportModalCreateTicket } from '@Apis/customer';
import { isLoggedIn } from '@User-operations';
import { StorageKeys } from '@Helpers/storage';
import { ATTACHMENT_ALLOWED_FILE_TYPES } from '@Constants/account';
import {
	FileWithPath,
	SupportQuery,
	SupportFormDataKey,
	ControlUnitExhanged,
	OperationStep,
	OperationStepValueMapping,
} from '@Constants/support';

export const containsForbiddenFileTypes = (uploadedFiles: File[]) => {
	return (
		uploadedFiles.filter(file => !ATTACHMENT_ALLOWED_FILE_TYPES.includes(file.name.split('.').pop().toLowerCase()))
			.length > 0
	);
};

// RTS 12 & 19
export const createSupportTicket1 = (
	uploadedFiles: FileWithPath[],
	language: string,
	description: string,
	query: SupportQuery
) => {
	const { productSelection, topicSelection, vehicleDivisionSelection, rtsVersion, dealerId } = query;
	const formData = new FormData();
	formData.append(SupportFormDataKey.DESCRIPTION, description);
	formData.append(SupportFormDataKey.LANG, language);
	formData.append(SupportFormDataKey.PRODUCT, get(productSelection, 'value.product'));
	formData.append(SupportFormDataKey.KEYWORD, get(topicSelection, 'value.topic'));
	formData.append(SupportFormDataKey.VEHICLE_DIV, get(vehicleDivisionSelection, 'label'));
	formData.append(SupportFormDataKey.RTS_TOPIC, rtsVersion);
	formData.append(SupportFormDataKey.QUERY, get(topicSelection, 'value.query'));
	formData.append(SupportFormDataKey.QUERY_TYPE, get(topicSelection, 'value.queryType'));
	formData.append(SupportFormDataKey.QUERY_DETAIL, get(topicSelection, 'value.queryDetails'));
	formData.append(SupportFormDataKey.APPLICATION, get(topicSelection, 'value.application'));
	if (dealerId) formData.append(SupportFormDataKey.DEALER_ID, dealerId);

	uploadedFiles.map(file => formData.append(SupportFormDataKey.ATTACHMENT, file, file.path));

	return rtsSupportModalCreateTicket(formData);
};

// RTS 36
export const createSupportTicket2 = (
	language: string,
	uploadedFiles: FileWithPath[],
	description: string,
	query: SupportQuery,
	systemID: string,
	phone: string,
	controlUnitExchanged: boolean
) => {
	const { productSelection, topicSelection, vehicleDivisionSelection, rtsVersion } = query;
	const formData = new FormData();
	formData.append(SupportFormDataKey.DESCRIPTION, description);
	formData.append(SupportFormDataKey.LANG, language);
	formData.append(SupportFormDataKey.PRODUCT, get(productSelection, 'value.product'));
	formData.append(SupportFormDataKey.KEYWORD, get(topicSelection, 'value.topic'));
	formData.append(SupportFormDataKey.VEHICLE_DIV, get(vehicleDivisionSelection, 'label'));
	formData.append(SupportFormDataKey.RTS_TOPIC, rtsVersion);
	formData.append(SupportFormDataKey.USE_CASE, get(topicSelection, 'value.useCase'));
	formData.append(
		SupportFormDataKey.OPERATION_STEP,
		OperationStepValueMapping[get(topicSelection, 'value.operationStep', OperationStep.GENERAL)] ||
			OperationStepValueMapping.GENERAL
	);
	formData.append(SupportFormDataKey.SYMPTOM, get(topicSelection, 'value.symptom'));
	formData.append(
		SupportFormDataKey.CONTROL_UNIT_EXCHANGED,
		controlUnitExchanged ? ControlUnitExhanged.YES : ControlUnitExhanged.NO
	);
	formData.append(SupportFormDataKey.SYSTEM_ID, systemID);
	formData.append(SupportFormDataKey.PHONE, phone);
	uploadedFiles.map(file => formData.append(SupportFormDataKey.ATTACHMENT, file, file.path));

	return rtsSupportModalCreateTicket(formData);
};

export const createSupportTicketPublic = (
	uploadedFiles: FileWithPath[],
	language: string,
	description: string,
	query: SupportQuery,
	name: string,
	surname: string,
	email: string,
	phone: string,
	company: string,
	clientNumber: string,
	street: string,
	addressNumber: string,
	postcode: string,
	town: string
) => {
	const { productSelection, topicSelection, vehicleDivisionSelection, rtsVersion } = query;
	const formData = new FormData();
	formData.append(SupportFormDataKey.DESCRIPTION, description);
	formData.append(SupportFormDataKey.LANG, language);
	formData.append(SupportFormDataKey.PRODUCT, get(productSelection, 'value.product'));
	formData.append(SupportFormDataKey.KEYWORD, get(topicSelection, 'value.topic'));
	formData.append(SupportFormDataKey.VEHICLE_DIV, get(vehicleDivisionSelection, 'label'));
	formData.append(SupportFormDataKey.RTS_TOPIC, rtsVersion);
	formData.append(SupportFormDataKey.NAME, name);
	formData.append(SupportFormDataKey.LAST_NAME, surname);
	formData.append(SupportFormDataKey.EMAIL, email);
	formData.append(SupportFormDataKey.COMPANY, company);
	formData.append(SupportFormDataKey.CLIENT_NUMBER, clientNumber);
	formData.append(SupportFormDataKey.STREET, street);
	formData.append(SupportFormDataKey.STREET_NUMBER, addressNumber);
	formData.append(SupportFormDataKey.POSTAL_CODE, postcode);
	formData.append(SupportFormDataKey.TOWN, town);
	formData.append(SupportFormDataKey.QUERY, get(topicSelection, 'value.query'));
	formData.append(SupportFormDataKey.QUERY_TYPE, get(topicSelection, 'value.queryType'));
	formData.append(SupportFormDataKey.QUERY_DETAIL, get(topicSelection, 'value.queryDetails'));
	formData.append(SupportFormDataKey.APPLICATION, get(topicSelection, 'value.application'));

	uploadedFiles.map(file => formData.append(SupportFormDataKey.ATTACHMENT, file, file.path));

	return rtsSupportModalCreatePublicTicket(formData);
};

export const cacheSupportModal = (query, router) => {
	const data = { query, path: router.pathname, asPath: router.asPath };
	sessionStorage.setItem(StorageKeys.STORAGE_KEY_SUPPORT_MODAL, JSON.stringify(data));
};

export const continueSupportModal = () => {
	const stringSupportModalStorageKey = sessionStorage.getItem(StorageKeys.STORAGE_KEY_SUPPORT_MODAL);
	if (stringSupportModalStorageKey) {
		const store = getOrCreateStore({});
		store.dispatch(
			isLoggedIn() ? CONTINUE_SUPPORT_MODAL(JSON.parse(stringSupportModalStorageKey)) : COMPLETE_SUPPORT_MODAL()
		);
	}
};

export const clearSupportModalStorageKey = () => {
	sessionStorage.removeItem(StorageKeys.STORAGE_KEY_SUPPORT_MODAL);
};

export const toSystemID = item => ({
	label: item.systemNumber,
	value: item,
});
