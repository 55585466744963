import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { AppDispatch } from '@Redux';
import { TOGGLE_ERROR_MODAL } from '@Reducers/modals/actions';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import Modal, { ModalSize } from '@isp/modal';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';

const ErrorModal = () => {
	const dispatch: AppDispatch = useDispatch();
	const onClose = () => dispatch(TOGGLE_ERROR_MODAL());

	const handleClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		onClose();
	};
	return (
		<>
			<Modal show header size={ModalSize.SM} onClose={handleClose}>
				<>
					<div className="train__justify-center train__center">
						<ISPIcon type={IconType.ERROR2} variant={IconVariant.BLOODRED} size={IconSize.MEDIUM} />
					</div>
					<div className="disp-grid train__justify-center train__center m-t-3 fs-16-px">
						<Trans i18nKey="shop:add-basket-directly-error">
							{"This didn't go as planned. This link seems to be broken."}
							{<br />}
							{
								<span className="train__justify-center train__center ">
									{'Please try again later or contact the sender.'}
								</span>
							}
						</Trans>
					</div>
				</>
			</Modal>
		</>
	);
};

export default ErrorModal;
