import get from 'lodash/get';

export const toSeoMeta = item => ({
	title: get(item, 'title') || null,
	description: get(item, 'description', null),
});

export const toMetaSettings = item => ({
	previewImage: get(item, 'previewImage._url', null),
	seoMeta: toSeoMeta(get(item, 'seoMeta', null)),
	seoNoIndex: get(item, 'seoNoIndex', null),
	seoNoFollow: get(item, 'seoNoFollow', null),
	seoFrequency: get(item, 'seoFrequency', null),
	seoPriority: get(item, 'seoPriority', null),
	ogTitle: get(item, 'ogTitle', null),
	ogDescription: get(item, 'ogDescription', null),
	ogImage: get(item, 'ogImage', null),
	twitterCardType: get(item, 'twitterCardType', null),
	twitterTitle: get(item, 'twitterTitle', null),
	twitterDescription: get(item, 'twitterDescription', null),
	twitterImage: get(item, 'twitterImage', null),
});

export interface SeoMeta {
	title: string;
	description: string;
}

export interface MetaSettings {
	previewImage: string;
	seoMeta: SeoMeta;
	seoNoIndex: boolean;
	seoNoFollow: boolean;
	seoFrequency: string;
	seoPriority: number;
	ogTitle: string;
	ogDescription: string;
	ogImage: string[];
	twitterCardType: string;
	twitterTitle: string;
	twitterDescription: string;
	twitterImage: string[];
}
