import get from 'lodash/get';
import { useTranslation } from '@Lib/i18n';
import BannerCard from '@Shared/header/IncidentManagementBanner/items/BannerCard';
import { LeitstandIcon, LeitstandNotification, LeitstandTitle } from '@Constants/notifications';

const LeitstandBanner = ({ notificationItem, onReadMore }) => {
	const { t } = useTranslation(['common']);
	const {
		leitstandType,
		eventMessage,
		notificationMessage,
		additionalMessages,
	} = notificationItem as LeitstandNotification;
	const { fullMessage, partialMessage, solvedMessage } = notificationMessage;
	const description = [eventMessage, fullMessage, partialMessage].filter(message => message).join(' · ');
	const showReadMore = solvedMessage !== '' || get(additionalMessages, 'length', 0) > 0;
	return (
		<BannerCard
			{...LeitstandIcon[leitstandType]}
			title={t(LeitstandTitle[leitstandType])}
			description={description}
			onReadMore={onReadMore}
			showReadMore={showReadMore}
		/>
	);
};

export default LeitstandBanner;
